import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button, CookieBanner, VerticalSpace } from "../components/workshop";

import logo from "../assets/images/logo.svg";
import lynnWithPhone from "../assets/images/lynn-with-phone.png";

const TechnicalRequirements = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  let technicalRequirementsBullets = t("technicalRequirements.bullets", { returnObjects: true });

  if (typeof technicalRequirementsBullets === "string") {
    technicalRequirementsBullets = [];
  }

  return (
    <>
      <div className="flex flex-col gap-4 justify-center items-center">
        <img src={logo} alt="logo" className="w-24 mb-6" />
        <h1
          dangerouslySetInnerHTML={{ __html: t("technicalRequirements.heading") }}
          className="text-5xl font-medium text-center font-righteous text-gray-250"
        />
        <p
          dangerouslySetInnerHTML={{ __html: t("technicalRequirements.subheading") }}
          className="text-xl font-semibold text-gray-100 text-center"
        />
        <VerticalSpace />
        <div className="bg-orange-600 text-gray-250 px-10 py-4 rounded-xl border border-gray-250">
          <ul className="flex flex-col gap-4 list-outside">
            {technicalRequirementsBullets?.map((bullet, index) => (
              <li key={index} className="text-lg">
                {bullet}
              </li>
            ))}
          </ul>
        </div>
        <VerticalSpace />

        <img src={lynnWithPhone} alt="logo" className="w-64" />
        <div className="w-full sm:max-w-md mx-auto flex flex-col gap-3">
          <Button type="primary" onClick={() => navigate("/demo/login")}>
            {t("technicalRequirements.button")}
          </Button>
        </div>
        <VerticalSpace />
      </div>

      <CookieBanner />
    </>
  );
};

export default TechnicalRequirements;
