import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { WorkshopContext } from "../contexts/workshop.context";

import { Button, CountdownTimer, ScreenBottom } from "../components/workshop";

import { defaultRoles } from "../util/workshop.util";

import { getNextRoute, getPreviousRoute } from "../util/general.util";

const TASK_ID = 9;

const Task9b = ({ summary = false, pdf = false, pdfVersionTwo = false }) => {
  const [wordOptions, setWordOptions] = useState([]);
  const [result, setResult] = useState([]); // { word, score }
  const [finalModSelections, setFinalModSelections] = useState({});
  const [started, setStarted] = useState(false); // Timer
  const [finished, setFinished] = useState(false);

  const {
    socket,
    user: { id, role } = {},
    themeConfig: { primary } = {},
    workshopDetails: { platform } = {}
  } = useContext(WorkshopContext);

  const { t } = useTranslation();

  useEffect(() => {
    try {
      if (wordOptions.length !== 0) return;

      const { attitudes } = t("mtParticipantsActivityNine", { returnObjects: true });

      setWordOptions(attitudes);
    } catch (error) {
      console.log(error);
    }
  }, [wordOptions]);

  const selectModWord = (index) => {
    if (role == defaultRoles.participant || finished) return;

    socket.emit("task9ModSelection", index);
  };

  useEffect(() => {
    if (!id && !summary) return;

    socket.emit("task9");

    socket.on("task9", ({ wordScores, modSelections }) => {
      if (Object.keys(wordScores).length !== 0) {
        setStarted(true);

        setResult(
          Object.entries(wordScores)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 8)
        );
      }

      setFinalModSelections(modSelections);
    });

    socket.emit("groupTaskStatus", TASK_ID, (status) => {
      if (status?.["all"]?.finished) {
        setFinished(true);
      }
    });

    socket.on("groupTaskStatus", (status) => {
      if (status?.[TASK_ID]?.["all"]?.finished) {
        setFinished(true);
      } else {
        setFinished(false);
      }
    });
  }, [id]);

  const handleBack = () => {
    const previousRoute = getPreviousRoute("/task-9/b", platform);
    socket.emit("navigate", previousRoute);
  };

  const handleNext = () => {
    const nextRoute = getNextRoute("/task-9/b", platform);
    socket.emit("navigate", nextRoute);
  };

  return (
    <div className="flex flex-col gap-4">
      {!summary && (
        <>
          <h1
            dangerouslySetInnerHTML={{
              __html: t("mtParticipantsActivityNineB.heading")
            }}
            className="text-5xl font-medium font-righteous text-gray-250"
          />

          {role === defaultRoles.participant && (
            <p
              dangerouslySetInnerHTML={{
                __html: t("mtParticipantsActivityNineB.subheading")
              }}
              className="text-xl text-gray-100"
            />
          )}
          {role === defaultRoles.moderator && (
            <p
              dangerouslySetInnerHTML={{
                __html: t("mtParticipantsActivityNineB.subheadingMod")
              }}
              className="text-xl text-gray-100"
            />
          )}
          {role === defaultRoles.moderatorAndParticipant && (
            <p
              dangerouslySetInnerHTML={{
                __html: t("mtParticipantsActivityNineB.subheadingModPart")
              }}
              className="text-xl text-gray-100"
            />
          )}
        </>
      )}

      <>
        {!summary && (
          <div className="flex flex-wrap gap-2 items-center justify-start content-center my-4">
            {result.map((w, index) => {
              const [wordIndex, wordScore] = w;
              const word = wordOptions[wordIndex];
              return (
                <span
                  key={index}
                  className={`w-full md:w-fit text-center px-4 py-2 bg-white-100 border-gray-250 text-black-100 rounded-full cursor-pointer`}
                  style={{
                    backgroundColor: finalModSelections[wordIndex] ? primary : "",
                    borderWidth: finalModSelections[wordIndex] ? "" : "1px",
                    color: finalModSelections[wordIndex] ? "#ffffff" : ""
                  }}
                  dangerouslySetInnerHTML={{
                    __html: `<span class="flex gap-2">${word} (${wordScore})</span>`
                  }}
                  onClick={() => selectModWord(wordIndex)}
                />
              );
            })}
          </div>
        )}
        <div
          className={`flex ${!pdfVersionTwo ? "flex-col" : "flex-row flex-wrap"} gap-3 items-center justify-center my-4 ${pdf ? "border-2 border-black-300 rounded-3xl w-fit mx-auto" : ""} ${pdf && !pdfVersionTwo ? "p-16" : "px-6 py-4"}`}>
          {pdf && pdfVersionTwo && (
            <h1
              dangerouslySetInnerHTML={{
                __html: t("teamCommunicationSummaryHeading")
              }}
              className="text-2xl font-bold w-full text-center"
            />
          )}
          {result.map((w, index) => {
            const [wordIndex] = w;
            const word = wordOptions[wordIndex];

            if (!finalModSelections[wordIndex]) return null;

            return (
              <span
                key={index}
                className={`${pdf && !pdfVersionTwo ? "text-7xl font-bold text-black-100" : "text-5xl font-bold text-black-200"}`}
                style={{
                  color: !pdf && primary ? primary : ""
                }}
                dangerouslySetInnerHTML={{
                  __html: word
                }}
              />
            );
          })}
        </div>
      </>

      {!summary && (
        <ScreenBottom>
          <div className="flex flex-col gap-2 min-w-96">
            {started && (
              <div className="bg-white-100 rounded-2xl border border-gray-250 p-4 flex flex-row gap-3 items-center justify-start w-full">
                <CountdownTimer
                  id={9}
                  start={started}
                  finished={finished}
                  totalTime={300}
                  onComplete={() => {
                    socket.emit("updateGroupTaskStatus", {
                      taskId: TASK_ID,
                      groupId: "all",
                      started: true,
                      finished: true
                    });
                  }}
                  onReset={
                    role !== defaultRoles.participant
                      ? () => {
                          socket.emit("updateGroupTaskStatus", {
                            taskId: TASK_ID,
                            groupId: "all",
                            started: true,
                            finished: false,
                            timerId: 9,
                            remainingTime: 299
                          });
                        }
                      : undefined
                  }
                />
                <p
                  className="text-base font-bold text-black-100"
                  dangerouslySetInnerHTML={{ __html: t("countdown.subheadingStarted") }}
                />
              </div>
            )}

            {role !== defaultRoles.participant && (
              <div className="w-full sm:max-w-md mx-auto flex items-center justify-center gap-4">
                <Button text={t("buttonBack")} onClick={handleBack} type="outline" />
                <Button text={t("buttonNext")} onClick={handleNext} />
              </div>
            )}
          </div>
        </ScreenBottom>
      )}
    </div>
  );
};

export default Task9b;
