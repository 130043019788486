import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { WorkshopContext } from "../contexts/workshop.context";

import {
  Button,
  MultiPopupInput,
  MultiSelectionPicker,
  ScreenBottom,
  SpeakingOrder,
  VerticalSpace,
  ParticipantsReadyList
} from "../components/workshop";
import { WordCloudRenderer } from "../components/insights";

import { ALLOWED_SKILLS, defaultRoles } from "../util/workshop.util";
import { getPreviousRoute, getNextRoute } from "../util/general.util";
import { parseWordsList, skillsPillasCloudColors } from "../util/wordcloud.util";

const Task10 = () => {
  const [taskOrderUsers, setTaskOrderUsers] = useState([]);
  const [completedUsers, setCompletedUsers] = useState([]);
  const [skillsSubmissions, setSkillsSubmissions] = useState([]);
  const [successPillarsSubmissions, setSuccessPillarsSubmissions] = useState([]);
  const [skillsWords, setSkillsWords] = useState([]);
  const [successPillarWords, setSuccessPillarWords] = useState([]);

  const {
    socket,
    user: { id, role } = {},
    workshopDetails: { platform } = {}
  } = useContext(WorkshopContext);

  const { t } = useTranslation();

  const successPillars = t("resources.successPillars", { returnObjects: true });

  const handleSkillsSubmit = (skills) => {
    if (skills.length === ALLOWED_SKILLS) {
      socket.emit("updateSkills", { userId: id, skills });
    }
  };

  useEffect(() => {
    socket.emit("taskOrder", 5, (users) => {
      if (users) setTaskOrderUsers(users);
    });

    socket.on("task10", (data) => {
      const {
        skillsSubmissions: skillsSubmissionsServer,
        successPillarsSubmissions: successPillarsSubmissionsServer
      } = data;

      const completed = Object.keys(skillsSubmissionsServer).filter(
        (userId) =>
          skillsSubmissionsServer?.[userId]?.length === ALLOWED_SKILLS &&
          successPillarsSubmissionsServer?.[userId]
      );

      setCompletedUsers(completed);

      const userSelectedSkills = skillsSubmissionsServer?.[id];
      const userSelectedSuccessPillar = successPillarsSubmissionsServer?.[id];

      if (userSelectedSkills) {
        setSkillsSubmissions([...userSelectedSkills]);
      }

      if (userSelectedSuccessPillar) {
        setSuccessPillarsSubmissions([userSelectedSuccessPillar]);
      }

      try {
        const parsedSkillsWords = parseWordsList(skillsSubmissionsServer, "skills");
        setSkillsWords(parsedSkillsWords);
      } catch (error) {
        console.log(error);
      }

      try {
        const parsedSuccessPillarWords = parseWordsList(
          successPillarsSubmissionsServer,
          "successPillars"
        );
        setSuccessPillarWords(parsedSuccessPillarWords);
      } catch (error) {
        console.log(error);
      }
    });

    socket.emit("task10");
  }, [id]);

  const handleBack = () => {
    const previousRoute = getPreviousRoute("/task-10", platform);
    socket.emit("navigate", previousRoute);
  };

  const handleNext = () => {
    const nextRoute = getNextRoute("/task-10", platform);
    if (nextRoute === "end_workshop") {
      socket.emit("endWorkshop");
    } else {
      socket.emit("navigate", nextRoute);
    }
  };

  return (
    <div className={"flex flex-col gap-4 mx-auto w-3/4"}>
      <div className="flex gap-4 items-center justify-start">
        <h1
          dangerouslySetInnerHTML={{ __html: t("mtParticipantsActivityTen.heading") }}
          className="text-5xl font-medium font-righteous text-gray-250"
        />
      </div>
      {role !== defaultRoles.moderator && (
        <>
          <p
            dangerouslySetInnerHTML={{ __html: t("mtParticipantsActivityTen.subheading") }}
            className="text-xl text-gray-100"
          />

          <SpeakingOrder participants={taskOrderUsers} />
        </>
      )}

      {/* TASK 10a */}
      {role !== defaultRoles.moderator && (
        <div className="mt-8">
          <h2
            dangerouslySetInnerHTML={{ __html: t("mtParticipantsActivityTen.heading1") }}
            className="text-2xl font-bold text-gray-250"
          />
          <p
            dangerouslySetInnerHTML={{
              __html: t("mtParticipantsActivityTen.description1")
            }}
            className="text-xl text-gray-100"
          />

          <MultiPopupInput
            buttonLabel={"mtParticipantsActivityTen.addSkillsButton"}
            popupTitle={"mtParticipantsActivityTen.skillsFieldLabel"}
            inputPlaceholder={"mtParticipantsActivityTen.skillInputPlaceholder"}
            submitButtonLabel={"mtParticipantsActivityTen.submitButton"}
            onSubmit={handleSkillsSubmit}
            allowedInputs={ALLOWED_SKILLS}
            dummySelections={skillsSubmissions}
            disableSelection={skillsSubmissions?.length > 0}
          />

          <VerticalSpace />
          <p
            dangerouslySetInnerHTML={{
              __html: t("mtParticipantsActivityTen.description3")
            }}
            className="text-xl text-gray-100"
          />
        </div>
      )}

      {role === defaultRoles.moderator && (
        <>
          <h2
            dangerouslySetInnerHTML={{ __html: t("mtParticipantsActivityTen.headingSkills") }}
            className="text-2xl font-bold text-gray-250"
          />
          <p
            dangerouslySetInnerHTML={{
              __html: t("mtParticipantsActivityTen.headingSkillsSub")
            }}
            className="text-xl text-gray-100"
          />
        </>
      )}

      <WordCloudRenderer
        id={"skills"}
        words={skillsWords}
        width={1500}
        height={1500}
        border="0.5px solid var(--MT-black, #454444)"
        backgroundColor="#fff"
        colorFunction={() => skillsPillasCloudColors.skillsWords}
        weightFactorFormula={(size) => size * 1.5}
      />

      {/* TASK 10b */}
      {role !== defaultRoles.moderator && (
        <div className="mt-8">
          <h2
            dangerouslySetInnerHTML={{ __html: t("mtParticipantsActivityTen.heading2") }}
            className="text-2xl font-bold"
          />

          <p
            dangerouslySetInnerHTML={{
              __html: t("mtParticipantsActivityTen.description2")
            }}
            className="text-xl text-gray-100"
          />

          <>
            <VerticalSpace />
            <MultiSelectionPicker
              socket={socket}
              moderator={role === defaultRoles.moderator}
              userId={id}
              updateId={"updateSuccessPillars"}
              title={t("mtParticipantsActivityTen.addSuccessPillarButton")}
              options={successPillars}
              maxOptions={1}
              dummySelections={successPillarsSubmissions}
              disableSelection={successPillarsSubmissions?.length > 0}
            />

            <VerticalSpace />
            <p
              dangerouslySetInnerHTML={{
                __html: t("mtParticipantsActivityTen.description4")
              }}
              className="text-xl text-gray-100"
            />
          </>
        </div>
      )}

      {role === defaultRoles.moderator && (
        <>
          <h2
            dangerouslySetInnerHTML={{
              __html: t("mtParticipantsActivityTen.headingSuccessPillars")
            }}
            className="text-2xl font-bold text-gray-250"
          />
          <p
            dangerouslySetInnerHTML={{
              __html: t("mtParticipantsActivityTen.headingSuccessPillarsSub")
            }}
            className="text-xl text-gray-100"
          />
        </>
      )}

      <WordCloudRenderer
        id={"successPillars"}
        words={successPillarWords}
        width={1500}
        height={1500}
        border="0.5px solid var(--MT-black, #454444)"
        backgroundColor="#fff"
        colorFunction={() => skillsPillasCloudColors.successPillarsWords}
        weightFactorFormula={(size) => size * 1.5}
      />

      {(role === defaultRoles.moderator ||
        (role === defaultRoles.moderatorAndParticipant &&
          completedUsers.includes(id?.toString()))) && (
        <div className="mt-8">
          <ParticipantsReadyList
            readyUsers={completedUsers}
            taskOrder={taskOrderUsers}
            showTimer={false}
            readyText={t("task.completed")}
            readyBGColor={"bg-green-300"}
          />
        </div>
      )}
      {role !== defaultRoles.participant && (
        <ScreenBottom>
          <Button text={t("buttonBack")} onClick={handleBack} type="outline" />
          <Button text={t("buttonNext")} onClick={handleNext} />
        </ScreenBottom>
      )}
    </div>
  );
};

export default Task10;
