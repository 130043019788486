import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import WordCloud from "wordcloud";

import { WorkshopContext } from "../../contexts/workshop.context";

import {
  ScreenBottom,
  Button,
  SpeakingOrder,
  MultiSelectionPicker,
  ParticipantsReadyList,
  ProgressBar
} from "../../components/workshop";

import { capitalizeFirstLetter } from "../../util/translations.util";

import logo from "../../assets/images/logo.svg";

const colors = ["#3E3E3E", "#666666", "#E59536", "#BE6A03", "#EEB52B", "#F28907"];

const canvasId = "wordcloud";

const BoosterWordCloud = () => {
  const { t } = useTranslation();

  const [isReady, setIsReady] = useState(false);
  const [taskOrderUsers, setTaskOrderUsers] = useState([]);
  const [allReady, setAllReady] = useState(false);
  const [readyUsers, setReadyUsers] = useState([]);
  const [renderStrengths, setRenderStrengths] = useState({});
  const [selectedStrengths, setSelectedStrengths] = useState([]);

  const Strengths = t("resources.strengths", { returnObjects: true });

  const {
    socket,
    user: { id } = {},
    workshopUsers,
    themeConfig: { primary } = {}
  } = useContext(WorkshopContext);

  socket.on("wordCloudReadyList", (data) => {
    console.log("wordCloudReadyList", data);
    if (!data.includes(id)) return;

    setReadyUsers(data);
    setIsReady(true);
  });

  socket.on("wordCloudReady", (result) => {
    setAllReady(result);
  });

  socket.on("strengths", (strengths) => {
    if (!Object.keys(strengths)?.length) return;
    setRenderStrengths(strengths);
  });

  useEffect(() => {
    if (!id) return;

    socket.emit("wordCloudReadyList", (data) => {
      if (!data.includes(id)) return;

      if (data?.length === workshopUsers?.length) {
        setAllReady(true);
        return;
      }

      setReadyUsers(data);
      setIsReady(true);
    });

    socket.emit("taskOrder", 1, (users) => {
      if (users) setTaskOrderUsers(users);
    });
  }, [id]);

  const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const renderWordCloud = (strengths) => {
    try {
      const wordsList = [];

      Object.keys(strengths).forEach((item) => {
        wordsList.push([capitalizeFirstLetter(item), strengths[item]]);
      });

      const canvas = document.getElementById(canvasId);

      WordCloud(canvas, {
        list: wordsList,
        fontFamily: "Arial, Helvetica, sans-serif",
        fontWeight: "bold",
        // gridSize: 1,
        gridSize: Math.round((24 * canvas.clientWidth) / 1024),
        weightFactor: function (size) {
          return Math.pow(size, 6);
        },
        color: function (word, weight) {
          return getRandomColor();
        },
        rotateRatio: 0.5,
        rotationSteps: 2,
        backgroundColor: "#ffffff",
        shrinkToFit: true,
        clearCanvas: true,
        drawOutOfBound: false
      });

      canvas.addEventListener("wordcloudstop", () => {
        console.log("wordcloudstop");
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (allReady) socket.emit("strengths");
  }, [allReady]);

  useEffect(() => {
    if (!allReady || Object.keys(renderStrengths).length === 0) return;

    renderWordCloud(renderStrengths);

    return () => {
      // stop the renderring
      WordCloud.stop();
    };
  }, [renderStrengths, allReady]);

  return isReady && !allReady ? (
    <>
      <ProgressBar
        defaultColor={"#fff"}
        progressColor={primary}
        total={6}
        progress={!allReady ? 3 : 4}
      />
      <h3
        dangerouslySetInnerHTML={{
          __html: t("worldCloud.waitingHelper")
        }}
        className="text-xl text-gray-250 text-center font-righteous mt-5"
      />
      <div>
        <ParticipantsReadyList
          idPrefix="wordcloud-strengths"
          readyUsers={readyUsers}
          showTimer={false}
        />
      </div>
    </>
  ) : (
    <>
      <div className="flex flex-col gap-4">
        <ProgressBar
          defaultColor={"#fff"}
          progressColor={primary}
          total={6}
          progress={!allReady ? 3 : 4}
        />

        <div>
          <div className=" flex justify-center relative mt-5">
            <img src={logo} className="w-8 h-8 absolute left-0 top-auto bottom-auto" />
            <h1
              dangerouslySetInnerHTML={{
                __html: !allReady ? t("worldCloud.title") : t("discussAndShare.title")
              }}
              className="text-3xl text-gray-250 text-center font-bold font-righteous"
            />
          </div>

          <p
            dangerouslySetInnerHTML={{
              __html: !allReady ? t("worldCloud.helper") : t("discussAndShare.helper")
            }}
            className="text-lg text-gray-250 text-center font-bold"
          />

          <p
            dangerouslySetInnerHTML={{
              __html: !allReady ? t("worldCloud.caption") : t("discussAndShare.caption")
            }}
            className="text-lg text-gray-250 text-center"
          />
        </div>

        <span className="block mb-6">
          <SpeakingOrder participants={taskOrderUsers} />
        </span>
        <span className={allReady ? "pointer-events-none" : ""}>
          <MultiSelectionPicker
            socket={socket}
            moderator={false}
            updateId={"updateStrengths"}
            title={t("worldCloud.buttonPlaceholder")}
            enableSearchOption={true}
            options={Strengths?.map((s) => s?.title)}
            updateLocalSelection={!allReady ? (list) => setSelectedStrengths(list) : undefined}
            dummySelections={allReady ? selectedStrengths : []}
          />
          {allReady && (
            <canvas
              id={canvasId}
              width={1000}
              height={1000}
              className={`mx-auto w-full rounded-2xl border border-gray-250`}
            />
          )}
        </span>

        <ScreenBottom>
          <Button
            text={t("next")}
            onClick={() =>
              !allReady
                ? socket.emit("wordCloudReady", id)
                : socket.emit("navigate", "/booster/benefits")
            }
          />
        </ScreenBottom>
      </div>
    </>
  );
};

export default BoosterWordCloud;
