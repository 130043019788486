import React, { useContext } from "react";
import { WorkshopContext } from "../../contexts/workshop.context";

const ParticipantsStatus = ({ heading, readyParticipants = [], badgeText = "" }) => {
  const { workshopUsers } = useContext(WorkshopContext);

  // Calculate lengths of the participant arrays
  const readyCount = readyParticipants.length;
  const totalCount = workshopUsers.length;

  return (
    <div className="relative w-full rounded-2xl bg-white-100 border border-gray-750 px-2 py-4 my-6">
      <div className="flex items-start justify-between">
        {heading && (
          <p
            className="text-xl mb-4 flex gap-2 font-bold"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        )}
      </div>
      <div className="flex items-start justify-between">
        <p className="text-xl font-bold px-4 py-1">{`${readyCount}/${totalCount}`}</p>
        <p className="text-xl rounded-full bg-emerald-200 px-4 py-1">{badgeText}</p>
      </div>
    </div>
  );
};

export default ParticipantsStatus;
