import React, { useState, useEffect } from "react";

import { htmlToText } from "../../util/translations.util";

const Input = ({
  maxCharacters,
  name,
  type = "text",
  placeholder,
  defultValue,
  onChange,
  onKeyDown,
  required,
  className = "",
  autoFocus,
  errorMessage = "This field is required"
}) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (defultValue !== undefined) setValue(defultValue);
  }, [defultValue]);

  return (
    <label htmlFor={name} className="block w-full">
      <input
        type={type}
        name={name}
        placeholder={htmlToText(placeholder)}
        value={value}
        onChange={(e) => {
          if (maxCharacters && e.target.value.length > maxCharacters) return;

          setValue(e.target.value);
          onChange(e.target.value);
        }}
        onKeyDown={(e) => {
          if (onKeyDown) {
            onKeyDown(e);
          }
        }}
        required={required}
        onInput={(e) => {
          e.target.setCustomValidity("");
        }}
        onInvalid={(e) => {
          e.target.setCustomValidity(htmlToText(errorMessage));
        }}
        className={`block w-full rounded-lg outline-none bg-white border-[0.5px] border-gray-250 text-black-100 py-4 px-6 text-lg placeholder:text-gray-300 ${className}`}
        autoFocus={autoFocus}
      />
    </label>
  );
};

export default Input;
