import { toast } from "react-toastify";
import { Button } from "../workshop";
import { useEffect, useState } from "react";
import { downloadFile } from "../../util/general.util";
import { ProgressBar } from "react-loader-spinner";
import { FaDownload } from "react-icons/fa";

const downloadRequested = {
  full: false
};

const downloadStarted = {
  full: false
};

const DownloadPdf = ({
  btnText = "Download PDF",
  buttonType = "basic",
  workshopId,
  downloadPDFTitle,
  socket,
  className = ""
}) => {
  const [downloading, setDownloading] = useState({ full: false });

  const downloadPDF = async (generationType = "full") => {
    try {
      setDownloading({ ...downloading, [generationType]: true });
      downloadRequested[generationType] = true;

      const summaryPageURL = window.location.href + "?pdf=true";

      if (window.location.origin.includes("localhost:")) {
        window.open(summaryPageURL, "_blank");
        setDownloading({ ...downloading, [generationType]: false });
        return;
      }

      // Request the existing PDF link and generation status with a callback
      socket.emit(
        "pdfLinkRequest",
        {
          id: workshopId,
          type: generationType,
          link: summaryPageURL,
          waitIDs: ["#loading", "#imageCloudRendering"]
        },
        async ({ pdfLink, isGenerating, error }) => {
          if (error) {
            toast.error("Error requesting PDF link");
            throw new Error("Error requesting PDF link", error);
          }

          if (pdfLink && !downloadStarted[generationType]) {
            downloadStarted[generationType] = true;
            downloadFile({
              pdfURL: pdfLink,
              type: generationType,
              downloading,
              setDownloading,
              downloadRequested,
              downloadStarted,
              downloadPDFTitle
            });
            return;
          }

          if (isGenerating) {
            console.log("PDF is being generated, please wait...");
            return;
          }
        }
      );
    } catch (error) {
      toast.error("Error generating PDF");
      console.log("Error generating PDF", error);
      setDownloading({ ...downloading, [generationType]: false });
      downloadRequested[generationType] = false;
      downloadStarted[generationType] = false;
    }
  };

  useEffect(() => {
    socket?.on("pdfLinkAvailable", ({ pdfLink, type, isGenerating, error }) => {
      console.log("pdf link available", pdfLink, type, isGenerating, error);
      if (error) {
        toast.error("Error requesting PDF link");
        throw new Error("Error requesting PDF link", error);
      }

      if (pdfLink && downloadRequested[type] && !downloadStarted[type]) {
        downloadStarted[type] = true;
        downloadFile({
          pdfURL: pdfLink,
          type: type,
          downloading,
          setDownloading,
          downloadRequested,
          downloadStarted,
          downloadPDFTitle
        });
        return;
      }

      if (isGenerating) {
        console.log("PDF is being generated, please wait...");
        return;
      }
    });
  }, []);

  if (buttonType === "icon")
    return (
      <>
        {downloading?.full ? (
          <span className="w-6 h-6">
            <ProgressBar
              visible={true}
              barColor="#ffffff"
              ariaLabel="button-loading"
              wrapperStyle={{}}
              wrapperClass="bars-wrapper"
            />
          </span>
        ) : (
          <span className="block w-full h-full" onClick={() => downloadPDF("full")}>
            <FaDownload />
          </span>
        )}
      </>
    );

  return (
    <Button
      loading={downloading?.full}
      onClick={() => downloadPDF("full")}
      text={btnText}
      className={`pointer-cursor max-w-fit my-4 ${className}`}></Button>
  );
};

export default DownloadPdf;
