import React, { useContext } from "react";
import { WorkshopContext } from "../../contexts/workshop.context";

const PdfFooter = () => {
  const { workshopDetails: { teamName } = {} } = useContext(WorkshopContext);

  return (
    <p className="text-[0.5rem] text-gray-250 absolute bottom-[2px] right-0 text-right uppercase">
      {teamName}
    </p>
  );
};

export default PdfFooter;
