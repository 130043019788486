import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { WorkshopContext } from "../../contexts/workshop.context";
import { htmlToText } from "../../util/translations.util";
import Button from "./button";
import CountdownTimer from "./countdownTimer";
import Participant from "./participant";

const TASK_ID = 2;

const ParticipantWithTimers = ({
  participant,
  groupIndex,
  participantIndex,
  start,
  finished,
  role,
  currentParticipantIndex,
  currentTimerIndex,
  onTimerComplete,
  onTimerUpdate
}) => {
  const { t } = useTranslation();
  const { socket } = useContext(WorkshopContext);
  const [localTimerIndex, setLocalTimerIndex] = useState(0);

  const selectTimerTime = (index) => {
    return index % 2 === 0 ? 180 : 90;
  };

  const handleTimerComplete = (timerIndex) => {
    setLocalTimerIndex(timerIndex + 1);
    onTimerComplete(participantIndex, timerIndex + 1);
    onTimerUpdate(groupIndex, participantIndex, timerIndex, 0);
  };

  const handleCompleteButton = (timerIndex) => {
    const fullIndex = participantIndex * 2 + timerIndex;
    socket.emit("updateTime", {
      id: `${TASK_ID}_${groupIndex}_${fullIndex}`,
      time: 0
    });
    handleTimerComplete(timerIndex);
  };

  const isCurrentParticipant = currentParticipantIndex === participantIndex;
  const canStartTimer = isCurrentParticipant && currentTimerIndex === localTimerIndex;

  return (
    <div className="bg-white-100 p-4 rounded-lg border border-gray-250">
      <div className="flex items-center justify-between mb-4">
        <Participant id={participant.id} name={participant.name} role={participant.role} />
      </div>
      <div className="grid grid-cols-2 gap-4">
        {[0, 1].map((timerIndex) => {
          const fullIndex = participantIndex * 2 + timerIndex;
          const isCurrentTimer = localTimerIndex === timerIndex;

          return (
            <div key={timerIndex} className="flex flex-col items-center">
              <CountdownTimer
                id={`${TASK_ID}_${groupIndex}_${fullIndex}`}
                totalTime={selectTimerTime(fullIndex)}
                start={start && canStartTimer && isCurrentTimer}
                finished={finished || localTimerIndex > timerIndex}
                onComplete={() => handleTimerComplete(timerIndex)}
              />
              <p
                className="text-sm text-center mt-2"
                dangerouslySetInnerHTML={{
                  __html:
                    timerIndex === 0
                      ? `${htmlToText(t("mtActivityFive.personOne")).split(" ")[0]} ${
                          participantIndex + 1
                        }`
                      : t("mtActivityFive.feedback")
                }}
              />
              {role !== "moderator" && (
                <Button
                  text={t("buttonComplete")}
                  disabled={finished || !canStartTimer || !isCurrentTimer}
                  className="text-sm sm:text-sm !py-2 !px-1 mt-1 min-w-fit"
                  onClick={() => handleCompleteButton(timerIndex)}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ParticipantWithTimers;
