import React, { useContext } from "react";

import { WorkshopContext } from "../contexts/workshop.context";
import {
  Header,
  Hero,
  TeamCommunicationPreferences,
  TeamRoles,
  TeamStrengths,
  Footer,
  TeamSkills,
  SuccessPillars,
  TeamMaps
} from "../components/insights";

const Insights = ({ demoWorkshop }) => {
  const {
    socket,
    workshopDetails: {
      id,
      teamName = "[Team Name]",
      roomData,
      pdf,
      platform,
      workshopDateString
    } = {}
  } = useContext(WorkshopContext);

  return (
    <section
      id="insights"
      className={`font-inter mx-auto ${pdf ? "bg-white-100" : ""}`}
      style={{
        maxWidth: !pdf && "1100px"
      }}>
      {!pdf && <Header id={id} socket={socket} platform={platform} />}
      {platform === "mft" ? (
        <>
          {!pdf && (
            <Hero
              teamName={teamName}
              workshopDate={workshopDateString}
              id={id}
              platform={platform}
              socket={socket}
            />
          )}
          <TeamMaps mapNames={roomData?.task4} platform={platform} isPdf={pdf} showStats={false} />
          <TeamStrengths
            strengths={roomData?.strengths}
            users={roomData?.users}
            strengthsSubmissions={roomData?.strengthsSubmissions}
            isPdf={pdf}
            platform={platform}
            showStats={false}
          />
          <TeamRoles
            roles={roomData?.roles}
            users={roomData?.users}
            rolesSubmissions={roomData?.rolesSubmissions}
            isPdf={pdf}
            platform={platform}
            showStats={false}
          />
          <TeamCommunicationPreferences
            starSelections={roomData?.task8}
            users={roomData?.users}
            isPdf={pdf}
            platform={platform}
            showStats={false}
          />
          {!pdf && <Footer platform={platform} />}
        </>
      ) : (
        <>
          {!pdf && (
            <Hero
              teamName={teamName}
              workshopDate={workshopDateString}
              id={id}
              socket={socket}
              demoWorkshop={demoWorkshop}
            />
          )}
          {!demoWorkshop && <TeamMaps mapNames={roomData?.task4} platform={platform} isPdf={pdf} />}
          <TeamStrengths
            strengths={roomData?.strengths}
            users={roomData?.users}
            strengthsSubmissions={roomData?.strengthsSubmissions}
            isPdf={pdf}
            platform={platform}
          />
          {!demoWorkshop && (
            <TeamRoles
              roles={roomData?.roles}
              users={roomData?.users}
              rolesSubmissions={roomData?.rolesSubmissions}
              isPdf={pdf}
              platform={platform}
            />
          )}
          <TeamCommunicationPreferences
            starSelections={roomData?.task8}
            attitudesSelections={roomData?.task9}
            users={roomData?.users}
            isPdf={pdf}
            platform={platform}
          />
          {!demoWorkshop && (
            <TeamSkills
              users={roomData?.users}
              successPillarsSubmissions={roomData?.task10?.successPillarsSubmissions}
              skillsSubmissions={roomData?.task10?.skillsSubmissions}
              isPdf={pdf}
            />
          )}
          {!demoWorkshop && (
            <SuccessPillars
              users={roomData?.users}
              successPillarsSubmissions={roomData?.task10?.successPillarsSubmissions}
              skillsSubmissions={roomData?.task10?.skillsSubmissions}
              isPdf={pdf}
            />
          )}
          {!pdf && <Footer platform={platform} />}
        </>
      )}
    </section>
  );
};

export default Insights;
