import React, { useState, useRef, useEffect } from "react";
import { DownArrowIcon } from "../../assets/icons";
import PdfFooter from "./pdfFooter";

const Accordion = ({ title, icon, content, isPdf, isStatic, defaultOpen = false }) => {
  const contentRef = useRef(null);

  const [open, setOpen] = useState(defaultOpen);

  const toggleAccordion = () => {
    if (isStatic || isPdf) return;

    setOpen(!open);
    contentRef.current.style.height = open ? "0px" : `${contentRef.current.scrollHeight}px`;
  };

  useEffect(() => {
    if ((isPdf || (isStatic && defaultOpen)) && contentRef.current?.scrollHeight) {
      setOpen(true);
      contentRef.current.style.height = "auto";
    }
  }, [isPdf, isStatic, defaultOpen, contentRef.current?.scrollHeight]);

  return (
    <div
      className={`block w-full ${
        isPdf ? "bg-white h-full relative" : "bg-[#FFEDD1] rounded-lg border-2 border-gray-250/40"
      } p-2 ${!isPdf ? "px-6 py-4" : ""}`}>
      {(title || icon) && (
        <div
          data-testid="accordion-header"
          className={`flex items-center justify-between ${!isStatic && !isPdf ? "cursor-pointer" : ""} select-none`}
          onClick={toggleAccordion}>
          <div className="flex items-center gap-2">
            {!isPdf && !isStatic && (
              <span
                className="w-10 h-10 transition-all"
                style={{
                  transform: open ? "rotate(180deg)" : "rotate(0deg)"
                }}>
                <DownArrowIcon fill="#454444" />
              </span>
            )}
            <div
              className="text-gray-250 text-xl font-extrabold"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
          <span
            data-testid="accordion-icon"
            className={`block ${isPdf ? "w-10 h-10" : "w-16 h-16"}`}
            dangerouslySetInnerHTML={{ __html: icon }}
          />
        </div>
      )}
      <div
        data-testid="accordion-content"
        className="transition-all overflow-hidden"
        ref={contentRef}
        style={{
          height: isPdf ? "200px" : open ? `${contentRef?.current?.scrollHeight}px` : "0px"
        }}>
        <div className="py-6">{content}</div>
      </div>
      {isPdf && <PdfFooter />}
    </div>
  );
};

export default Accordion;
