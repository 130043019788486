import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CountdownTimer from "./countdownTimer";
import Participant from "./participant";

const TASK_ID = 2;

const ModeratorParticipantWithTimers = ({
  participant,
  groupIndex,
  participantIndex,
  start,
  finished,
  currentParticipantIndex,
  currentTimerIndex,
  onTimerComplete,
  timerStates
}) => {
  const { t } = useTranslation();
  const [localTimerIndex, setLocalTimerIndex] = useState(0);

  const selectTimerTime = (index) => {
    return index % 2 === 0 ? 180 : 90;
  };

  const handleTimerComplete = (timerIndex) => {
    setLocalTimerIndex(timerIndex + 1);
    onTimerComplete(participantIndex, timerIndex + 1);
  };

  const isCurrentParticipant = currentParticipantIndex === participantIndex;
  const canStartTimer = isCurrentParticipant && currentTimerIndex === localTimerIndex;

  const isParticipantFinished =
    timerStates[`${groupIndex}_${participantIndex}_0`]?.finished &&
    timerStates[`${groupIndex}_${participantIndex}_1`]?.finished;

  return (
    <div className="bg-white-100 p-4 rounded-lg border border-gray-250">
      <div className="flex items-center justify-between mb-4">
        <Participant id={participant.id} name={participant.name} role={participant.role} />
        {isParticipantFinished && (
          <span className="bg-green-500 text-white px-2 py-1 rounded-full text-sm">
            {t("task.completed")}
          </span>
        )}
        <div className="grid grid-cols-2 gap-4">
          {[0, 1].map((timerIndex) => {
            const fullIndex = participantIndex * 2 + timerIndex;
            const isCurrentTimer = localTimerIndex === timerIndex;
            const timerState = timerStates[`${groupIndex}_${participantIndex}_${timerIndex}`] || {};

            return (
              <div key={timerIndex} className="flex flex-col items-center">
                <CountdownTimer
                  id={`${TASK_ID}_${groupIndex}_${fullIndex}`}
                  totalTime={selectTimerTime(fullIndex)}
                  start={start && canStartTimer && isCurrentTimer}
                  finished={finished || localTimerIndex > timerIndex || timerState.finished}
                  onComplete={() => handleTimerComplete(timerIndex)}
                  initialTime={timerState.time}
                  size={45}
                  isMini={true}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ModeratorParticipantWithTimers;
