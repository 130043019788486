import React, { useState, useEffect } from "react";
import markerSDK from "@marker.io/browser";

import bug from "../assets/svgLogos/bug.svg";

const BugReport = () => {
  const [widget, setWidget] = useState(null);

  useEffect(() => {
    (async () => {
      const w = await markerSDK.loadWidget({ project: "65e5cdcae87b50952da15c9e" });
      w.hide();
      w.on("show", function listener() {
        w.hide();
      });
      setWidget(w);
    })();
  }, []);

  if (!widget) return null;

  return (
    <button
      className="fixed bottom-4 right-4 z-[60] w-12 h-12 cursor-pointer outline-none"
      onClick={() => widget?.capture("advanced")}>
      <img src={bug} alt="bug" />
    </button>
  );
};

export default BugReport;
