const StrengthsImagePicker = ({ options = [], onSelect }) => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-6 gap-3">
      {options.map((option) => (
        <button key={option.id} className="w-full" onClick={() => onSelect(option.id)}>
          <img src={option.img} alt={option.id} className="w-full" />
        </button>
      ))}
    </div>
  );
};

export default StrengthsImagePicker;
