import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Emoji from "./emoji";
import Popup from "./popup";
import Button from "./button";

import { defaultRoles } from "../../util/workshop.util";
import { CheckedIcon, SendIcon } from "../../assets/icons";

const EMOJIS = [
  "1F60E",
  "1FAE3",
  "1F44F",
  "1F440",
  "1F4A9",
  "1F607",
  "1F605",
  "1F978",
  "2615",
  "1FAE1"
];

const EmojiSender = ({ socket, usersList = [], currentUserId }) => {
  const { t } = useTranslation();

  const [receivedEmojiPopup, setReceivedEmojiPopup] = useState(false);
  const [receivedEmojiDetails, setReceivedEmojiDetails] = useState({});

  const [openEmojiPopup, setOpenEmojiPopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const selectUser = ({ index }) => {
    setSelectedUser(index);
  };

  const selectEmoji = (index) => {
    setSelectedEmoji(index);
  };

  useEffect(() => {
    const users = JSON.parse(
      JSON.stringify(usersList.filter((workshopUser) => workshopUser.id !== currentUserId))
    );
    setFilteredUsers(users);
  }, []);

  useEffect(() => {
    const users = JSON.parse(
      JSON.stringify(usersList.filter((workshopUser) => workshopUser.id !== currentUserId))
    );
    setFilteredUsers(users);
  }, [usersList]);

  useEffect(() => {
    socket?.on("receiveEmoji", ({ sender, receiver, emojiIndex }) => {
      if (receiver?.id === currentUserId) {
        setReceivedEmojiDetails({ sender, receiver, emojiIndex });
        setReceivedEmojiPopup(true);
      }
    });
  }, []);

  return (
    <>
      {filteredUsers.length > 0 && (
        <button
          className="rounded-full p-2.5 bg-[#e59536] cursor-pointer w-10 h-10"
          onClick={() => setOpenEmojiPopup(!openEmojiPopup)}>
          <SendIcon />
        </button>
      )}

      {/* EMOJI SEND */}
      <Popup
        open={openEmojiPopup}
        setOpen={() => {
          setSelectedUser(null);
          setSelectedEmoji(null);
          setOpenEmojiPopup(false);
        }}
        autoHeight={false}>
        <p
          className="text-left font-bold text-2xl text-gray-250"
          dangerouslySetInnerHTML={{ __html: t("emojiSender.heading") }}
        />
        <div className="bg-white mt-4 mb-8 p-6 border-[0.5px] border-gray-250 rounded-lg ">
          <div className="grid grid-cols-5 grid-rows-2 gap-4">
            {EMOJIS.map((emoji, index) => (
              <Emoji
                key={index}
                index={index}
                symbol={emoji}
                selected={selectedEmoji === index}
                onSelect={selectEmoji}
              />
            ))}
          </div>
        </div>

        <p
          className="text-left font-bold text-2xl text-gray-250"
          dangerouslySetInnerHTML={{ __html: t("emojiSender.subHeading") }}
        />
        <div className="flex flex-col gap-4 p-4">
          {filteredUsers.map((option, index) => (
            <Option
              key={index}
              index={index}
              title={option.name}
              selected={selectedUser === index}
              onClick={selectUser}
            />
          ))}
        </div>

        <div className="w-full flex gap-4 mt-5">
          <Button
            disabled={selectedEmoji === null || selectedUser === null}
            text={t("emojiSender.send")}
            onClick={() => {
              const sender = usersList.find((user) => user.id === currentUserId);
              const receiver = filteredUsers[selectedUser];
              socket.emit("sendEmoji", {
                sender,
                receiver,
                emojiIndex: selectedEmoji
              });
              setOpenEmojiPopup(false);
            }}
          />
        </div>
      </Popup>

      {/* EMOJI RECEIVE */}
      <Popup
        open={receivedEmojiPopup}
        setOpen={() => {
          setSelectedUser(null);
          setReceivedEmojiPopup(false);
        }}
        autoHeight={true}
        bottomPadding={false}
        mobilePopupStyle={false}>
        <p className="text-center font-bold text-2xl text-gray-250 mb-8">
          <span
            className="text-orange-100 text-5xl font-righteous bold"
            style={{
              "-webkit-text-stroke":
                receivedEmojiDetails?.sender?.role === defaultRoles.moderator && "1px #E59536",
              color: receivedEmojiDetails?.sender?.role === defaultRoles.moderator && "#fff"
            }}>
            {receivedEmojiDetails.sender?.name}
          </span>
          <br />
          <span dangerouslySetInnerHTML={{ __html: t("emojiSender.received.heading") }} />
        </p>
        <Emoji
          symbol={EMOJIS[receivedEmojiDetails.emojiIndex || 0]}
          className="text-8xl"
          hasHoverState={false}
        />
        <div className="w-full flex gap-4 mt-6">
          {/* <span className="w-1/2">
            <Button
              text={t("emojiSender.received.close")}
              onClick={() => setReceivedEmojiPopup(false)}
              type="secondary"
            />
          </span> */}
          <Button
            text={t("emojiSender.received.reply")}
            onClick={() => {
              const senderIndex = usersList.findIndex(
                (user) => user.id === receivedEmojiDetails.sender?.id
              );
              setSelectedUser(senderIndex);
              setReceivedEmojiPopup(false);
              setOpenEmojiPopup(true);
            }}
          />
        </div>
      </Popup>
    </>
  );
};

export default EmojiSender;

const Option = ({ index, title, selected, onClick }) => {
  return (
    <div
      className={`grid grid-cols-[1fr_30px] cursor-pointer`}
      onClick={() => onClick({ index, title })}>
      <span
        className="text-xl font-medium text-gray-250"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <span className="w-6 h-6 justify-self-end">
        <CheckedIcon bg={selected ? "#E59536" : "none"} />
      </span>
    </div>
  );
};
