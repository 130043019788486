import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { WorkshopContext } from "../contexts/workshop.context";

import { ScreenBottom, Button, SpeakingOrder, MapPicker } from "../components/workshop";

import { defaultPlatforms, defaultRoles } from "../util/workshop.util";
import { getPreviousRoute, getNextRoute } from "../util/general.util";

const Task4 = ({ summary = false, pdf = false }) => {
  const [taskOrderUsers, setTaskOrderUsers] = useState([]);

  const {
    socket,
    user: { id, name, role } = {},
    workshopDetails: { platform } = {}
  } = useContext(WorkshopContext);

  const { t } = useTranslation();

  useEffect(() => {
    socket.emit("taskOrder", 1, (users) => {
      if (users) setTaskOrderUsers(users);
    });
  }, []);

  const handleBack = () => {
    const previousRoute = getPreviousRoute("/task-4", platform);
    socket.emit("navigate", previousRoute);
  };

  const handleNext = () => {
    const nextRoute = getNextRoute("/task-4", platform);
    socket.emit("navigate", nextRoute);
  };

  return (
    <div className={`${pdf ? "block h-full w-full" : "flex flex-col gap-4"}`}>
      {!summary && (
        <>
          <h1
            dangerouslySetInnerHTML={{ __html: t("mtParticipantPreActivity.headingThree") }}
            className="text-5xl font-medium font-righteous text-gray-250"
          />

          {role === defaultRoles.participant && (
            <p
              dangerouslySetInnerHTML={{ __html: t("mtParticipantActivityScreen.paragraphThree") }}
              className="text-xl text-gray-100"
            />
          )}
          {role === defaultRoles.moderator && (
            <p
              dangerouslySetInnerHTML={{
                __html: t("mtParticipantActivityScreen.paragraphThreeMod")
              }}
              className="text-xl text-gray-100"
            />
          )}
          {role === defaultRoles.moderatorAndParticipant && (
            <p
              dangerouslySetInnerHTML={{
                __html: t("mtParticipantActivityScreen.paragraphThreeModPart")
              }}
              className="text-xl text-gray-100"
            />
          )}

          <SpeakingOrder participants={taskOrderUsers} />
        </>
      )}

      <MapPicker
        socket={socket}
        id={"task4"}
        updateId={"updateTask4"}
        name={name}
        userId={id}
        moderator={role === defaultRoles.moderator}
        summary={summary}
        pdf={pdf}
      />

      {role !== defaultRoles.participant && !summary && (
        <ScreenBottom>
          <Button text={t("buttonBack")} onClick={handleBack} type="outline" />
          <Button text={t("buttonNext")} onClick={handleNext} />
        </ScreenBottom>
      )}
    </div>
  );
};

export default Task4;
