import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import Player from "@vimeo/player";

import { WorkshopContext } from "../../contexts/workshop.context";

import { Loader } from "../loaders";
import participantVideoThumbnail from "../../assets/images/participantVideoProgress.png";
import { defaultPlatforms, defaultRoles, VideoById } from "../../util/workshop.util";

const Video = ({ videoId, workshopProgress = 0 }) => {
  const [loading, setLoading] = useState(true);

  const {
    socket,
    user: { role } = {},
    workshopDetails: { platform } = {}
  } = useContext(WorkshopContext);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!role || (role === defaultRoles.participant && platform !== defaultPlatforms.mtOnline))
      return;

    // Set vimeo player
    const player = new Player("video" + videoId, {
      id: VideoById[platform][videoId][i18n.language],
      autoplay: false,
      controls: true,
      muted: false,
      loop: false,
      quality: "1080p"
    });

    player.on("loaded", () => {
      setLoading(false);
    });

    player.setVolume(1);

    player.on("timeupdate", ({ duration, seconds }) => {
      socket?.emit("updateVideoTime", { videoId, seconds });
    });

    // Get current time and status of video
    socket?.emit("videoTime", videoId, (time) => {
      if (time === undefined) return;

      player
        .getDuration()
        .then(function (duration) {
          player.setCurrentTime(time).catch((error) => console.log(error));
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  }, [videoId, role]);

  if (role === defaultRoles.participant && platform !== defaultPlatforms.mtOnline)
    return (
      <div className="min-h-[100dvh] w-full flex items-center justify-center">
        <div className="flex flex-col gap-4 justify-center items-center px-6">
          <img
            src={participantVideoThumbnail}
            className="block w-full max-w-2xl"
            alt="Waiting for video"
          />
          <h1
            dangerouslySetInnerHTML={{ __html: t("mtParticipantVideos.heading") }}
            className="text-5xl text-gray-250 font-medium text-center font-righteous"
          />
          <p
            dangerouslySetInnerHTML={{ __html: t("mtParticipantVideos.paragraph") }}
            className="text-2xl text-gray-100 font-semibold text-center"
          />
        </div>
      </div>
    );
  else
    return (
      <div className="flex flex-col gap-4">
        <div className="relative w-full rounded-full bg-white-100 border-[0.5px] border-gray-250 h-3 mb-5">
          <span
            className="block rounded-full bg-orange-100 h-full"
            style={{ width: `${workshopProgress * 100}%` }}
          />
        </div>
        <h1
          dangerouslySetInnerHTML={{ __html: t("mtModeratorVideos.videoTitle") + " " + videoId }}
          className="text-5xl font-medium font-righteous text-gray-250"
        />
        <p
          dangerouslySetInnerHTML={{ __html: t("mtModeratorVideos.subheading") }}
          className="text-xl text-gray-100"
        />

        <div className="relative w-full h-auto vimeo rounded-xl overflow-hidden">
          <div id={"video" + videoId} />
        </div>
        {loading && <Loader />}
      </div>
    );
};

export default Video;
