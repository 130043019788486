import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { WorkshopContext } from "../contexts/workshop.context";

import { Button, ParticipantsList, ScreenBottom } from "../components/workshop";

import { defaultRoles } from "../util/workshop.util";

import { getNextRoute, getPreviousRoute } from "../util/general.util";

const Task1 = () => {
  const [taskOrderUsers, setTaskOrderUsers] = useState([]);

  const {
    socket,
    user: { role } = {},
    workshopDetails: { platform } = {}
  } = useContext(WorkshopContext);

  const { t } = useTranslation();

  useEffect(() => {
    socket.emit("taskOrder", 0, (users) => {
      if (users) setTaskOrderUsers(users);
    });
  }, []);

  const handleBack = () => {
    const previousRoute = getPreviousRoute("/task-1", platform);
    socket.emit("navigate", previousRoute);
  };

  const handleNext = () => {
    const nextRoute = getNextRoute("/task-1", platform);
    socket.emit("navigate", nextRoute);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-4 items-center justify-start">
        {/* <img src={logo} className="w-8 h-8" alt="logo" /> */}
        <h1
          dangerouslySetInnerHTML={{ __html: t("mtParticipantTaskScreen.heading") }}
          className="text-5xl font-medium font-righteous text-gray-250"
        />
      </div>

      {role === defaultRoles.participant && (
        <p
          dangerouslySetInnerHTML={{ __html: t("mtParticipantTaskScreen.subheading1") }}
          className="text-xl text-gray-100"
        />
      )}

      {role === defaultRoles.moderator && (
        <p
          dangerouslySetInnerHTML={{ __html: t("mtParticipantTaskScreen.subheading1Mod") }}
          className="text-xl text-gray-100"
        />
      )}

      {role === defaultRoles.moderatorAndParticipant && (
        <p
          dangerouslySetInnerHTML={{ __html: t("mtParticipantTaskScreen.subheading1ModPart") }}
          className="text-xl text-gray-100"
        />
      )}

      <ParticipantsList
        heading={t("mtParticipantTaskScreen.subheading")}
        participants={taskOrderUsers}
      />

      {role !== defaultRoles.participant && (
        <ScreenBottom>
          <Button text={t("buttonBack")} onClick={handleBack} type="outline" />
          <Button text={t("buttonNext")} onClick={handleNext} />
        </ScreenBottom>
      )}
    </div>
  );
};

export default Task1;
