import { capitalizeFirstLetter } from "./translations.util";

export const skillsPillasCloudColors = {
  successPillarsWords: "#5DB447",
  skillsWords: "#9C65DC"
};

export const parseWordsList = (newWords, type = "skills") => {
  try {
    const baseSize = type === "skills" ? 45 : 60;
    // const baseSize = 1;

    const wordsList = [];
    const countsList = {};

    const flatListOfWords = Object.values(newWords)
      .flat()
      ?.map((word) => word.toLowerCase());

    flatListOfWords.map((word) => {
      countsList[word] = (countsList[word] || 0) + 1;
    });

    if (flatListOfWords.length > 0) {
      Object.keys(countsList).forEach((word) => {
        const count = countsList[word];
        const size = baseSize * count;

        wordsList.push([capitalizeFirstLetter(word), size]);
      });
    }

    return wordsList;
  } catch (error) {
    console.log(error);
    return [];
  }
};
