import React, { useEffect, useState } from "react";

import Popup from "./popup";
import { AddIcon, MagnifierIcon, HeadIcon, PersonIcon, RadarIcon } from "../../assets/icons";

const iconsMap = {
  magnifier: MagnifierIcon,
  head: HeadIcon,
  person: PersonIcon,
  radar: RadarIcon
};

const SymbolPicker = ({
  socket,
  id,
  updateId,
  groupId,
  popupHeading,
  chosenOptionHeadings,
  chosenOptionsBottomIcons,
  optionsHeadings,
  optionsImages,
  finishedTask
}) => {
  const [open, setOpen] = useState(false);
  const [chosenSymbols, setChosenSymbols] = useState({});

  useEffect(() => {
    socket.on(id, (data) => {
      try {
        if (data?.[groupId]) {
          setChosenSymbols(data[groupId]);
        }
      } catch (error) {
        console.log(error);
      }
    });

    socket.emit(id, groupId);
  }, [id]);

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        {chosenOptionHeadings.map(({ header, subheader, subheading }, index) => {
          return (
            <div
              key={index}
              className="relative bg-white-100 rounded-2xl border border-gray-250 p-2 min-h-72 flex flex-col gap-4 items-center cursor-pointer"
              onClick={() => {
                if (!finishedTask) setOpen(index);
              }}>
              <span>
                {header && (
                  <p
                    className="text-2xl font-bold text-gray-250 text-center"
                    dangerouslySetInnerHTML={{ __html: header }}
                  />
                )}

                <div className="relative">
                  {(subheader || subheading) && (
                    <p
                      className="text-xl text-gray-100 text-center font-bold"
                      dangerouslySetInnerHTML={{ __html: subheader ?? subheading }}
                    />
                  )}
                  {chosenOptionsBottomIcons?.[index] && (
                    <div className="mt-2 flex flex-row gap-2 items-center justify-center">
                      {chosenOptionsBottomIcons[index].map((icon, iconIndex) => {
                        const Icon = iconsMap[icon];
                        if (!Icon) return null;

                        return (
                          <span key={iconIndex} className="w-12 h-12 bg-gray-200 rounded-full p-2">
                            <Icon />
                          </span>
                        );
                      })}
                    </div>
                  )}
                </div>
              </span>

              {chosenSymbols?.[index] === undefined && (
                <span className="w-14 h-14 sm:w-16 sm:h-16 absolute left-1/2 top-[65%] sm:top-[55%] transform -translate-x-1/2 -translate-y-1/2">
                  <AddIcon />
                </span>
              )}

              {chosenSymbols?.[index] !== undefined && (
                <img
                  src={optionsImages?.[chosenSymbols?.[index]]}
                  alt={header}
                  className="block w-full h-auto rounded-2xl"
                />
              )}
            </div>
          );
        })}
      </div>

      <Popup open={open !== false} setOpen={setOpen} autoHeight={true}>
        <>
          {popupHeading?.main && (
            <h1
              dangerouslySetInnerHTML={{ __html: popupHeading?.main }}
              className="text-3xl font-bold text-gray-250"
            />
          )}

          {popupHeading?.subheading && (
            <p
              dangerouslySetInnerHTML={{ __html: popupHeading?.subheading }}
              className="text-2xl text-gray-100"
            />
          )}
          <div className="grid grid-cols-2 gap-4 mt-6">
            {optionsHeadings.map(({ heading }, index) => {
              return (
                <div
                  key={index}
                  className="rounded-lg bg-gray-200 overflow-hidden cursor-pointer"
                  onClick={() => {
                    if (finishedTask) return;

                    const update = { ...chosenSymbols, [open]: index };
                    setChosenSymbols(update);
                    socket.emit(updateId, { groupId, update });
                    setOpen(false);
                  }}>
                  <img src={optionsImages[index]} alt={heading} />
                  <p
                    className="text-base text-black-100 text-center mt-2"
                    dangerouslySetInnerHTML={{ __html: heading }}
                  />
                </div>
              );
            })}
          </div>
        </>
      </Popup>
    </>
  );
};

export default SymbolPicker;
