import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  StarIcon,
  HexagonIcon,
  MagnifierIcon,
  HeadIcon,
  PersonIcon,
  RadarIcon
} from "../../assets/icons";
import ScreenBottom from "./screenBottom";
import Button from "./button";

import { defaultRoles } from "../../util/workshop.util";
import {
  demoWorkshopUsers,
  getInitials,
  getLocalStorage,
  getNextRoute,
  getPreviousRoute,
  setLocalStorage
} from "../../util/general.util";
import { useLocation, useNavigate } from "react-router-dom";

const iconsMap = [
  [MagnifierIcon, HeadIcon],
  [MagnifierIcon, PersonIcon],
  [RadarIcon, HeadIcon],
  [RadarIcon, PersonIcon]
];

const allDemoUsers = demoWorkshopUsers();

const demoStarPositions = {
  a: {
    0: {
      optionTwo: [],
      optionThree: [
        {
          ...allDemoUsers[2],
          type: "a"
        }
      ],
      optionFour: [
        {
          ...allDemoUsers[2],
          type: "a"
        },
        {
          ...allDemoUsers[6],
          type: "a"
        }
      ]
    },
    1: {
      optionTwo: [
        {
          ...allDemoUsers[0],
          type: "a"
        },
        {
          ...allDemoUsers[4],
          type: "a"
        },
        {
          ...allDemoUsers[5],
          type: "a"
        }
      ],
      optionThree: [
        {
          ...allDemoUsers[0],
          type: "a"
        },
        {
          ...allDemoUsers[4],
          type: "a"
        },
        {
          ...allDemoUsers[1],
          type: "a"
        }
      ]
    },
    2: {
      optionTwo: [],
      optionThree: [
        {
          ...allDemoUsers[3],
          type: "a"
        },
        {
          ...allDemoUsers[6],
          type: "a"
        }
      ],
      optionFour: [
        {
          ...allDemoUsers[3],
          type: "a"
        }
      ]
    },
    3: {
      optionTwo: [
        {
          ...allDemoUsers[1],
          type: "a"
        }
      ],
      optionThree: [],
      optionFour: [
        {
          ...allDemoUsers[5],
          type: "a"
        }
      ]
    }
  },
  b: {
    0: {
      optionOne: [],
      optionTwo: [],
      optionThree: [
        {
          ...allDemoUsers[0],
          type: "b"
        },
        {
          ...allDemoUsers[1],
          type: "b"
        },
        {
          ...allDemoUsers[4],
          type: "b"
        },
        {
          ...allDemoUsers[5],
          type: "b"
        }
      ],
      optionFour: [
        {
          ...allDemoUsers[0],
          type: "b"
        },
        {
          ...allDemoUsers[1],
          type: "b"
        }
      ]
    },
    1: {
      optionOne: [
        {
          ...allDemoUsers[6],
          type: "b"
        }
      ],
      optionTwo: [],
      optionThree: [],
      optionFour: [
        {
          ...allDemoUsers[3],
          type: "b"
        }
      ]
    },
    2: {
      optionOne: [
        {
          ...allDemoUsers[5],
          type: "b"
        }
      ],
      optionTwo: [],
      optionThree: [
        {
          ...allDemoUsers[4],
          type: "b"
        }
      ],
      optionFour: []
    },
    3: {
      optionOne: [],
      optionTwo: [],
      optionThree: [
        {
          ...allDemoUsers[2],
          type: "b"
        },
        {
          ...allDemoUsers[6],
          type: "b"
        }
      ],
      optionFour: [
        {
          ...allDemoUsers[2],
          type: "b"
        },
        {
          ...allDemoUsers[3],
          type: "b"
        }
      ]
    }
  }
};

const StarPicker = ({
  socket,
  id,
  updateId,
  type,
  name,
  userId,
  role,
  platform = "mt",
  summary = false,
  pdf = false,
  demoWorkshop = false
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [options, setOptions] = useState([[]]);
  const [confirmed, setConfirmed] = useState(false);
  const [starPositions, setStarPositions] = useState({ a: {}, b: {} });
  const [starAPositions, setStarAPositions] = useState({});
  const [totalSelections, setTotalSelections] = useState(0);
  const [isPDFBuilding, setIsPDFBuilding] = useState(true);
  const currentRoute = useLocation().pathname.split("/task")[1];

  useEffect(() => {
    setOptions(t("resources.starsList", { returnObjects: true }));

    if (!type || !id || !userId) return;

    socket?.on(id, (data) => {
      try {
        if (data) {
          if (summary) {
            setStarPositions(data);
            setConfirmed(true);
            // hide div here
            setIsPDFBuilding(false);
            return;
          }

          if (type === "b") {
            setStarAPositions(data?.["a"]);
          }

          let usersExists = 0;

          const typeStars = data?.[type];

          // Find if user.id exists in any level
          Object.keys(typeStars)?.forEach((index) => {
            Object.keys(typeStars[index])?.forEach((key) => {
              typeStars[index][key]?.forEach((user) => {
                if (user.id === userId) {
                  usersExists++;
                }
              });
            });
          });

          if (usersExists > 0 || role === defaultRoles.moderator) {
            setStarPositions(data);
            setConfirmed(true);
          } else {
            setConfirmed(false);
          }
        }
      } catch (error) {
        console.log(error);
      }
    });

    socket?.emit(id);
  }, [id, type, userId]);

  useEffect(() => {
    let stars = { a: {}, b: {} };
    if (demoWorkshop) {
      if (type === "b") {
        // Get stored type 'a' selections from localStorage
        const previousSelections = getLocalStorage(id);

        stars = {
          a: previousSelections?.a || demoStarPositions.a,
          b: demoStarPositions.b
        };
      } else {
        stars = { a: demoStarPositions.a, b: {} };
      }
    }
    setStarPositions(stars);
    setConfirmed(false);
    setTotalSelections(0);
  }, [type]);

  const handleBack = () => {
    const previousRoute = getPreviousRoute(`/task${currentRoute}`, platform);
    if (demoWorkshop) {
      navigate(previousRoute);
    } else {
      socket?.emit("navigate", previousRoute);
    }
  };

  const handleNext = () => {
    const nextRoute = getNextRoute(`/task${currentRoute}`, platform);

    if (demoWorkshop) {
      navigate(nextRoute);
      return;
    }

    if (nextRoute === "end_workshop") {
      socket?.emit("endWorkshop");
      return;
    }

    socket?.emit("navigate", nextRoute);
  };

  return (
    <>
      {pdf && isPDFBuilding && <span id="pdf-ok"></span>}
      <div className="w-full h-auto overflow-x-auto whitespace-nowrap horizonal xl:flex xl:gap-5 xl:flex-nowrap ">
        {[0, 1, 2, 3].map(({}, index) => {
          return (
            <div
              key={index}
              className={`relative bg-white-100 px-2 py-4 rounded-2xl ${!pdf && !summary ? "min-h-[60vh] xl:min-h-fit" : ""} ${!pdf ? "w-80 mx-5 xl:w-1/4 xl:mx-0 pb-8" : "w-1/4"}  inline-block ${!summary && "border border-gray-250"}`}>
              <div
                className={`relative w-full ${!pdf ? "h-52" : "h-32"} rounded-lg mb-2 overflow-hidden`}>
                <img
                  src={require(`../../assets/images/maps/${index}.png`)}
                  className="block relative w-full h-full object-cover"
                  alt=""
                />

                <div className="absolute right-2 bottom-2 flex flex-row gap-2">
                  {iconsMap[index].map((Icon, iconIndex) => {
                    return (
                      <span key={iconIndex} className="w-12 h-12 bg-gray-250 rounded-full p-2">
                        <Icon bg={"#454444"} iconColor={"#fff"} />
                      </span>
                    );
                  })}
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <p
                  className={`${!pdf ? "text-xl" : "text-base"} text-orange-100 font-bold w-full text-left whitespace-break-spaces`}
                  dangerouslySetInnerHTML={{ __html: t("mtActivityEight.listHeading") }}
                />
                {options?.[index] &&
                  Object.keys(options[index]).map((key, optionsIndex) => {
                    const text = options[index][key];

                    const selectedOption =
                      starPositions?.[type]?.[index]?.[key] &&
                      starPositions?.[type]?.[index]?.[key]?.find((user) => user?.id === userId);

                    return (
                      <div
                        key={optionsIndex}
                        className={`relative flex ${confirmed ? "flex-col" : "flex-row"} ${!pdf ? "gap-2" : "gap-0"} items-start justify-between ${!pdf ? "my-2" : "my-0"}`}>
                        <div>
                          <p
                            className={`text-gray-100 wrapper w-full whitespace-break-spaces ${pdf ? "text-xs" : ""}`}
                            dangerouslySetInnerHTML={{ __html: text }}
                          />
                          {!confirmed && starAPositions !== undefined && (
                            <div
                              className={`w-full h-full flex flex-wrap gap-1 items-satart justify-start content-center`}>
                              {starAPositions?.[index]?.[key] !== undefined &&
                                starAPositions?.[index]?.[key].map(({ name }, symIndex) => {
                                  return (
                                    <Symbol
                                      key={"a" + symIndex}
                                      type={"a"}
                                      name={name}
                                      filled={true}
                                      small={true}
                                    />
                                  );
                                })}
                            </div>
                          )}
                        </div>
                        {!confirmed && role !== defaultRoles.moderator && (
                          <span
                            className="relative cursor-pointer"
                            onClick={() => {
                              if (selectedOption) {
                                // Unselect it
                                const updatedObject = {
                                  ...starPositions,
                                  [type]: {
                                    ...starPositions[type],
                                    [index]: {
                                      ...starPositions[type][index],
                                      [key]: [
                                        ...(starPositions?.[type]?.[index]?.[key] ?? []).filter(
                                          (user) => user.id !== userId
                                        )
                                      ]
                                    }
                                  }
                                };
                                if (demoWorkshop) setLocalStorage(id, updatedObject);
                                setStarPositions(updatedObject);
                                setTotalSelections(totalSelections - 1);
                                return;
                              }

                              if (totalSelections > 1) return;
                              const updatedObject = {
                                ...starPositions,
                                [type]: {
                                  ...starPositions[type],
                                  [index]: {
                                    ...starPositions[type][index],
                                    [key]: [
                                      ...(starPositions?.[type]?.[index]?.[key] ?? []),
                                      { id: userId, name, type }
                                    ]
                                  }
                                }
                              };
                              if (demoWorkshop) setLocalStorage(id, updatedObject);
                              setStarPositions(updatedObject);
                              setTotalSelections(totalSelections + 1);
                            }}>
                            <Symbol type={type} filled={selectedOption} />
                          </span>
                        )}

                        {confirmed && (
                          <div
                            className={`w-full h-full flex flex-wrap gap-1 items-center justify-start content-center ${pdf ? "mt-0" : "-mt-3"}`}>
                            {starPositions?.["a"]?.[index]?.[key] !== undefined &&
                              starPositions?.["a"]?.[index]?.[key].map(({ name }, symIndex) => {
                                return (
                                  <Symbol
                                    key={"a" + symIndex}
                                    type={"a"}
                                    name={name}
                                    filled={true}
                                    small={true}
                                    pdf={pdf}
                                  />
                                );
                              })}
                            {starPositions?.["b"]?.[index]?.[key] !== undefined &&
                              starPositions?.["b"]?.[index]?.[key].map(({ name }, symIndex) => {
                                return (
                                  <Symbol
                                    key={"b" + symIndex}
                                    type={"b"}
                                    name={name}
                                    filled={true}
                                    small={true}
                                    pdf={pdf}
                                  />
                                );
                              })}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </div>

      {!summary && (
        <ScreenBottom>
          {!confirmed &&
            (role === defaultRoles.participant ||
              role === defaultRoles.moderatorAndParticipant) && (
              <Button
                text={t("buttonConfirm")}
                disabled={totalSelections === 0}
                onClick={() => {
                  if (demoWorkshop) {
                    setConfirmed(true);
                    return;
                  }
                  socket?.emit(updateId, starPositions);
                }}
              />
            )}
          {(role === defaultRoles.moderator ||
            (confirmed && role === defaultRoles.moderatorAndParticipant)) && (
            <>
              {!demoWorkshop && (
                <Button text={t("buttonBack")} onClick={handleBack} type={"outline"} />
              )}
              <Button text={t("buttonNext")} onClick={handleNext} />
            </>
          )}
        </ScreenBottom>
      )}
    </>
  );
};

const Symbol = ({ name, type, filled = false, small = false, pdf = false }) => {
  const initials = getInitials(name);
  return (
    <span
      className={`relative ${!small ? "w-10 h-10" : !pdf ? "w-8 h-8" : "w-7 h-7"} flex items-center justify-center`}>
      <span className="w-full h-full absolute top-0 left-0">
        {type === "a" ? <StarIcon filled={filled} /> : <HexagonIcon filled={filled} />}
      </span>
      <span className={`relative ${!small ? "text-sm" : "text-xs"} text-white-200 uppercase`}>
        {initials}
      </span>
    </span>
  );
};

export default StarPicker;
