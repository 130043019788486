export const mapSelectionsToUsers = (users, submissions) => {
  const result = {};

  users.forEach((user) => {
    const userId = user.id;
    const userName = user.name;

    const userSubmissions = submissions[userId];

    if (userSubmissions !== undefined) {
      userSubmissions.forEach((selection) => {
        if (!result[selection]) {
          result[selection] = [];
        }
        result[selection].push(userName);
      });
    }
  });

  return result;
};
