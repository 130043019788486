import axios from "axios";
import { defaultPlatforms } from "./workshop.util";

export const sortListByLocale = (list, lang, customPath = "") => {
  return list
    .map((item) =>
      customPath ? { ...item, [customPath]: item?.[customPath]?.trim() } : item.trim()
    )
    .sort((a, b) =>
      customPath
        ? a?.[customPath]?.localeCompare(b?.[customPath], lang, { sensitivity: "base" })
        : a.localeCompare(b, lang, { sensitivity: "base" })
    );
};

export const getInitials = (name) => {
  // Check if name is provided and is a string
  if (!name || typeof name !== "string") {
    return "";
  }

  // Trim the name and split it into parts
  const parts = name.trim().split(/\s+/);

  if (parts.length === 0) {
    return "";
  } else if (parts.length === 1) {
    // For single word names, take first two letters (or one if name is single character)
    return name.substring(0, 2).toUpperCase();
  } else {
    // For multiple word names, take first letter of first two words
    return parts
      .slice(0, 2)
      .map((part) => part[0] || "")
      .join("")
      .toUpperCase();
  }
};

const routes = {
  mt: [
    "/video/1",
    "/task-1",
    "/video/2",
    "/task-2",
    "/video/3",
    "/task-3",
    "/video/4",
    "/task-4",
    "/video/5",
    "/task-5",
    "/video/6",
    "/task-6",
    "/video/7",
    "/task-7",
    "/video/8",
    "/task-8/a",
    "/task-8/b",
    "/video/9",
    "/task-9",
    "/task-9/b",
    "/video/10",
    "/task-10"
  ],
  mtOnline: [
    "/online/video/1",
    "/online/task-1",
    "/online/video/2",
    "/online/task-2",
    "/online/video/3",
    "/online/task-3",
    "/online/video/4",
    "/online/task-4",
    "/online/video/5",
    "/online/task-5",
    "/online/video/6",
    "/online/task-6",
    "/online/video/7",
    "/online/task-7",
    "/online/video/8",
    "/online/task-8/a",
    "/online/task-8/b",
    "/online/video/9",
    "/online/task-9",
    "/online/task-9/b",
    "/online/video/10",
    "/online/task-10"
  ],
  mft: [
    "/video/1",
    "/task-4",
    "/video/2",
    "/task-6",
    "/video/3",
    "/task-7",
    "/video/4",
    "/task-8/a",
    "/task-8/b"
  ],
  mtDemo: [
    "/demo/video/1",
    "/demo/task-1",
    "/demo/video/2",
    "/demo/task-2/a",
    "/demo/task-2/b",
    "/demo/end"
  ]
};

export const getPreviousRoute = (currentRoute, platform) => {
  let localCurrentRoute = currentRoute;
  if (platform === defaultPlatforms.mtDemo && !currentRoute.includes("demo"))
    localCurrentRoute = "/demo" + currentRoute;
  const routeList = routes[platform];
  const currentIndex = routeList.indexOf(localCurrentRoute);

  if (currentIndex > 0) {
    return routeList[currentIndex - 1];
  }

  return currentRoute; // Return the current route if it's the first one
};

export const getNextRoute = (currentRoute, platform) => {
  let localCurrentRoute = currentRoute;
  if (platform === defaultPlatforms.mtDemo && !currentRoute.includes("demo"))
    localCurrentRoute = "/demo" + currentRoute;
  const routeList = routes[platform];

  const currentIndex = routeList.indexOf(
    `${platform === defaultPlatforms.mtOnline ? "/online" : ""}${localCurrentRoute}`
  );

  if (currentIndex === -1) {
    return currentRoute;
  }

  if (currentIndex === routeList.length - 1) {
    return "end_workshop";
  }

  if (currentIndex < routeList.length - 1) {
    return routeList[currentIndex + 1];
  }

  return currentRoute; // Return the current route if it's the last one
};

export const downloadFile = ({
  pdfURL,
  type,
  downloading,
  setDownloading,
  downloadRequested,
  downloadStarted,
  downloadPDFTitle
}) => {
  let parsedPdfUrl = pdfURL;
  try {
    parsedPdfUrl = JSON.parse(pdfURL) || pdfURL;
  } catch (error) {
    console.log("parsing of url failed", pdfURL, error);
  }
  // Download as blob from URL
  axios
    .get(parsedPdfUrl, { responseType: "blob" })
    .then((response) => {
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      link.download = downloadPDFTitle ? `${downloadPDFTitle}.pdf` : "Insights.pdf";
      link.click();
    })
    .catch((error) => {
      console.log("Error downloading PDF", error);
    })
    .finally(() => {
      setDownloading({ ...downloading, [type]: false });
      downloadRequested[type] = false;
      downloadStarted[type] = false;
    });
};

export const setLocalStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = (key) => {
  return JSON.parse(sessionStorage.getItem(key));
};

export const removeLocalStorage = (key) => {
  sessionStorage.removeItem(key);
};

export const clearLocalStorage = () => {
  sessionStorage.clear();
};

export const demoWorkshopUsers = () => {
  const users = [
    { name: "Yavan Columbus", id: "yavan", role: "participant" },
    { name: "Fern Magellan", id: "fern", role: "participant" },
    { name: "Fabio Ulysses", id: "fabio", role: "participant" },
    { name: "Tristan Mariner", id: "tristan", role: "participant" },
    { name: "Patrick Neptune", id: "patrick", role: "participant" },
    { name: "Regina O’Malley", id: "regina", role: "participant" },
    { name: "Lisa Baret", id: "lisa", role: "participant" }
  ];

  return users;
};
