import React from "react";

import Participant from "./participant";
import { HourglassIcon } from "../../assets/icons";

const ParticipantsList = ({
  heading,
  participants,
  started,
  finished,
  verticalListOnly = true,
  moderator,
  removeUser,
  userOwnId,
  completedText = "",
  showHourGlass = true
}) => {
  return (
    <div className="relative w-full">
      <div className="flex items-start justify-between">
        {heading && (
          <p
            className="text-2xl mb-4 flex gap-2 font-bold text-gray-250"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        )}
        {showHourGlass && started && !finished && (
          <span className="block w-6 h-6">
            <HourglassIcon className="w-6 h-6" />
          </span>
        )}
        {finished && (
          <span
            className="bg-emerald-200 rounded-full px-4 py-1"
            dangerouslySetInnerHTML={{ __html: completedText }}
          />
        )}
      </div>
      <div
        className={`flex flex-col gap-2 ${!verticalListOnly && "sm:flex-row sm:flex-wrap sm:gap-6"}`}>
        {participants.map(({ id, name, role }) => (
          <Participant
            key={id}
            id={id}
            name={name}
            role={role}
            moderator={moderator}
            removeUser={id !== userOwnId ? removeUser : null}
          />
        ))}
      </div>
    </div>
  );
};

export default ParticipantsList;
