import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { WorkshopContext } from "../../contexts/workshop.context";

import { CloseIcon, InfoIcon } from "../../assets/icons";

const NewUserNotification = () => {
  const { t } = useTranslation();

  const [userName, setUserName] = useState(null);

  const { socket } = useContext(WorkshopContext);

  useEffect(() => {
    if (!socket) return;

    socket.on("newUserNotification", (user) => {
      setUserName(user?.name);

      setTimeout(() => {
        setUserName(null);
      }, 1500);
    });
  }, [socket]);

  if (!userName) return null;

  return (
    <div className="w-full sm:max-w-96 fixed top-0 right-0 z-50 bg-white-100 shadow-md py-5 px-10">
      <button
        className="block absolute right-4 top-3 w-8 h-8 cursor-pointer"
        onClick={() => setUserName(null)}>
        <CloseIcon />
      </button>

      <div className="flex gap-4">
        <span className="block w-16 h-16">
          <InfoIcon />
        </span>
        <div className="flex flex-col gap-2">
          <p
            className="text-lg font-bold text-red-100 w-full"
            dangerouslySetInnerHTML={{ __html: `${userName} ${t("newUserNotification.heading")}` }}
          />

          <p
            className="text-base text-gray-100 w-full"
            dangerouslySetInnerHTML={{ __html: t("newUserNotification.subHeading") }}
          />
          {/* 
          <button
            className="block bg-gray-200 text-gray-100 rounded-full px-6 py-2 text-lg"
            dangerouslySetInnerHTML={{ __html: t("newUserNotification.button") }}
            onClick={() => socket.emit("navigate", "/wait")}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default NewUserNotification;
