import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { WorkshopContext } from "../../contexts/workshop.context";

import { ScreenBottom, Button, ProgressBar } from "../../components/workshop";

import logo from "../../assets/images/logo.svg";

const Benefits = () => {
  const { t } = useTranslation();

  const benefitCards = t("benefits.cards", { returnObjects: true });

  const { socket, themeConfig: { primary } = {} } = useContext(WorkshopContext);
  return (
    <div className="flex flex-col gap-4">
      <ProgressBar defaultColor={"#fff"} progressColor={primary} total={6} progress={6} />

      <div className="mt-5 relative">
        <img src={logo} className="w-8 h-8 absolute left-0" />
        <h1
          dangerouslySetInnerHTML={{
            __html: t("benefits.title")
          }}
          className="text-3xl text-gray-250 text-center mb-5 font-bold font-righteous"
        />
      </div>

      <div className="w-full overflow-auto block whitespace-nowrap pb-6">
        {Object.keys(benefitCards).map((_, index) => {
          const card = benefitCards[`card${index + 1}`];
          return (
            <div
              key={index}
              className="w-[300px] min-h-[150px] align-top inline-block mr-8 sm:w-1/3 bg-white-100 border border-gray-250 rounded-xl p-4"
              id={`benefits-${index}`}>
              <span
                className="block w-20 h-20 mx-auto"
                dangerouslySetInnerHTML={{ __html: card?.icon }}
              />
              <div className="mt-5">
                <h3
                  className="text-base text-gray-250 font-bold font-righteous whitespace-pre-wrap"
                  dangerouslySetInnerHTML={{ __html: card?.title }}
                />
                <p
                  className="text-base text-gray-100 whitespace-pre-wrap"
                  dangerouslySetInnerHTML={{ __html: card?.caption }}
                />
              </div>
            </div>
          );
        })}
      </div>

      <ScreenBottom>
        <Button
          text={t("competeSession")}
          onClick={() => socket.emit("navigate", "/booster/thank-you")}
        />
      </ScreenBottom>
    </div>
  );
};

export default Benefits;
