import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { WorkshopContext } from "../../contexts/workshop.context";

import { ScreenBottom, Button, ProgressBar } from "../../components/workshop";

const Understanding = () => {
  const { t } = useTranslation();

  const { socket, themeConfig: { primary } = {} } = useContext(WorkshopContext);
  return (
    <div className="flex flex-col gap-4">
      <ProgressBar defaultColor={"#fff"} progressColor={primary} total={6} progress={1} />

      <div className="bg-white-100 border border-gray-250 px-4 rounded-2xl mt-5 mx-auto w-full py-8">
        <h1
          dangerouslySetInnerHTML={{
            __html: t("understanding.title")
          }}
          className="text-3xl text-gray-250 text-center mb-5 font-bold font-righteous"
        />
        <p
          dangerouslySetInnerHTML={{
            __html: t("understanding.caption")
          }}
          className="text-lg text-gray-250 text-center"
        />
      </div>

      <ScreenBottom>
        <Button
          text={t("continue")}
          onClick={() => socket.emit("navigate", "/booster/choose-strengths")}
        />
      </ScreenBottom>
    </div>
  );
};

export default Understanding;
