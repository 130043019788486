import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Popup from "./popup";
import ParticipantsList from "./participantsList";

import { SpeakerOrderIcon } from "../../assets/icons";

const UserGroup = ({ participants }) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <div
      className="mt-6 rounded-lg p-4 border-[1px] border-gray-100 flex flex-row gap-3 items-center justify-start cursor-pointer"
      onClick={() => setOpen(!open)}>
      <span className="w-8 h-8">
        <SpeakerOrderIcon />
      </span>
      <span
        className="text-lg font-normal text-gray-100"
        dangerouslySetInnerHTML={{ __html: t("userGroup.button") }}
      />

      <Popup open={open} setOpen={setOpen}>
        <ParticipantsList heading={t("userGroup.popupHeading")} participants={participants} />
      </Popup>
    </div>
  );
};

export default UserGroup;
