import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AddIcon, CloseIcon } from "../../assets/icons";
import Button from "./button";
import Input from "./input";
import Popup from "./popup";

const MultiPopupInput = ({
  buttonLabel,
  popupTitle,
  inputPlaceholder,
  submitButtonLabel,
  onSubmit,
  allowedInputs,
  maxCharacters = 35,
  onOpen,
  dummySelections = [],
  disableSelection
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputs, setInputs] = useState([]);

  const handleOpen = () => {
    if (disableSelection) return;

    setOpen(true);
    onOpen && onOpen();
  };

  const handleAddInput = () => {
    if (!inputValue || !inputValue.trim()) {
      return;
    }
    if (inputs.length < allowedInputs) {
      const trimmedValue = inputValue.trim();
      setInputs([...inputs, trimmedValue]);
      setInputValue("");
    }
  };

  const handleRemoveInput = (index) => {
    const newInputs = inputs.filter((_, i) => i !== index);
    setInputs(newInputs);
  };

  const handleSubmit = () => {
    onSubmit && onSubmit(inputs);
    setOpen(false);
  };

  useEffect(() => {
    setInputs(dummySelections);
  }, [dummySelections]);

  return (
    <div>
      <button
        className="rounded-lg bg-white-100 text-gray-300 p-4 mt-6 flex flex-col w-full text-left border border-black-100"
        onClick={handleOpen}>
        <span
          className="block text-md font-normal text-gray-300"
          dangerouslySetInnerHTML={{ __html: t(buttonLabel) }}
        />
        <span className="text-lg font-normal text-gray-300">{inputs.join(", ")}</span>
      </button>

      <Popup open={open} setOpen={setOpen}>
        <div className="mt-4 mb-8">
          <h3 className="font-bold" dangerouslySetInnerHTML={{ __html: t(popupTitle) }} />
          <div className="flex items-center bg-gray-200 rounded-lg pr-1">
            <Input
              maxCharacters={maxCharacters}
              placeholder={t(inputPlaceholder)}
              defultValue={inputValue}
              onChange={setInputValue}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (inputs.length === allowedInputs) {
                    handleSubmit();
                    return;
                  }
                  handleAddInput();
                }
              }}
              className="rounded-r-none"
              autoFocus={true}
            />
            <button
              className="bg-gray-200 rounded-lg rounded-l-none w-10 h-10"
              onClick={handleAddInput}>
              <div className="w-10 h-10">
                <AddIcon />
              </div>
            </button>
          </div>
          <div className="flex items-center justify-between mt-1 text-gray-100">
            <span className="flex items-center gap-1">
              {inputValue?.length}/{maxCharacters}
              <span
                dangerouslySetInnerHTML={{
                  __html: t("multiInputMaxCharacters")
                }}
              />
            </span>
            <span className="flex items-center gap-1 tracking-wide">
              <span>
                {inputs.length}/{allowedInputs}
              </span>
              <span
                dangerouslySetInnerHTML={{
                  __html: t("multiInputTotalInputsAdded")
                }}
              />
            </span>
          </div>

          <div className="rounded-xl bg-gray-200 p-2 my-8">
            <span
              className="uppercase text-sm text-gray-100"
              dangerouslySetInnerHTML={{
                __html: t("multiInputAddedInputsTitle")
              }}
            />
            <ul className="font-bold">
              {inputs?.map((input, index) => (
                <li key={index} className="flex justify-between items-center mb-2">
                  <span>{input}</span>
                  <button
                    onClick={() => handleRemoveInput(index)}
                    className="text-gray-500 hover:text-gray-700">
                    <CloseIcon className="w-4 h-4" />
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <Button
            disabled={inputs.length < allowedInputs}
            text={t(submitButtonLabel)}
            onClick={handleSubmit}
          />
        </div>
      </Popup>
    </div>
  );
};

export default MultiPopupInput;
