import React from "react";

import { htmlToText } from "../../util/translations.util";

const Radio = ({
  id,
  name,
  label,
  onChange,
  required,
  defaultChecked,
  errorMessage = "This field is required"
}) => {
  return (
    <label className="flex items-center gap-2 cursor-pointer" htmlFor={id}>
      <input
        id={id}
        type="radio"
        name={name}
        defaultChecked={defaultChecked}
        onChange={(e) => onChange(e.target.id)}
        required={required}
        onInput={(e) => {
          e.target.setCustomValidity("");
        }}
        onInvalid={(e) => {
          e.target.setCustomValidity(htmlToText(errorMessage));
        }}
      />
      <span className="text-xl text-gray-100" dangerouslySetInnerHTML={{ __html: label }} />
    </label>
  );
};

export default Radio;
