import React, { useState, useEffect, useContext } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { WorkshopContext } from "../../contexts/workshop.context";
import { FaRedo } from "react-icons/fa";

const CountdownTimer = ({
  id,
  start = false,
  finished,
  totalTime = 60,
  onComplete,
  onReset,
  greyedOnCompletion = false,
  size = 75,
  strokeWidth = 4,
  isMini = false,
  customClass = ""
}) => {
  const [startTimer, setStartTimer] = useState(false);
  const [startFrom, setStartFrom] = useState();

  const { socket } = useContext(WorkshopContext);

  const renderTime = ({ remainingTime }) => {
    if (remainingTime <= 0 || finished) {
      return (
        <div
          className={`font-bold ${greyedOnCompletion && finished ? "text-[#cccccc]" : "text-black-100"} ${isMini ? "text-base" : "text-xl md:text-lg"} ${onReset ? "cursor-pointer select-none hover:rotate-180 transition-all" : ""}`}
          onClick={() => {
            if (onReset) onReset();
          }}>
          {onReset ? <FaRedo color="#000" /> : "✅"}
        </div>
      );
    }

    if (isNaN(remainingTime)) {
      return (
        <div
          className={`text-xl font-bold ${greyedOnCompletion && finished ? "text-[#cccccc]" : "text-black-100"}`}>
          0
        </div>
      );
    }

    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;

    if (remainingTime === 60) return <div className="text-xl font-bold text-black-100">1:00</div>;
    else if (remainingTime < 60)
      return <div className="text-xl font-bold text-black-100">0:{remainingTime}</div>;
    else
      return (
        <div className="text-xl font-bold text-black-100">{`${minutes}:${seconds < 10 ? "0" + seconds : seconds}`}</div>
      );
  };

  const timerColors =
    greyedOnCompletion && finished
      ? ["#cccccc", "#cccccc", "#cccccc", "#cccccc", "#cccccc", "#cccccc"]
      : ["#292828", "#141414", "#2d2b2b", "#413b3b", "#413b3b", "#413b3b"];

  useEffect(() => {
    try {
      socket.emit("remainingTime", id, (time) => {
        if (!isNaN(time) && time !== null && time !== undefined) {
          if (time === 0) {
            onComplete && onComplete();
            setStartFrom(Number(time));
            return;
          }

          setStartFrom(Number(time));
        } else {
          setStartFrom(totalTime);
        }
      });

      socket.on("remainingTime", ({ timerId, remainingTime: time }) => {
        if (timerId !== id) return;

        if (!isNaN(time) && time !== null && time !== undefined) {
          if (time === 0) {
            onComplete && onComplete();
            setStartFrom(Number(time));
            return;
          }

          setStartFrom(Number(time));
        } else {
          setStartFrom(totalTime);
        }
      });

      socket.on("completeTimer", (incomingId) => {
        try {
          if (id === incomingId) {
            onComplete && onComplete();
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [id, finished]);

  useEffect(() => {
    if (!startFrom || !start) return;

    setStartTimer(start);
  }, [startFrom, start]);

  if (!startTimer || finished)
    return (
      <div className={customClass}>
        <CountdownCircleTimer
          isPlaying={false}
          duration={totalTime !== startFrom && !isNaN(startFrom) ? startFrom : totalTime}
          initialRemainingTime={!isNaN(startFrom) ? startFrom : totalTime}
          size={size}
          strokeWidth={strokeWidth}
          colors={timerColors}
          colorsTime={[
            totalTime,
            totalTime * 0.75,
            totalTime * 0.5,
            totalTime * 0.25,
            totalTime * 0.1,
            0
          ]}>
          {renderTime}
        </CountdownCircleTimer>
      </div>
    );
  else
    return (
      <div className={customClass}>
        <CountdownCircleTimer
          key={`${id}-${startFrom}`}
          isPlaying={startTimer}
          duration={totalTime !== startFrom ? startFrom : totalTime}
          initialRemainingTime={startFrom}
          size={size}
          strokeWidth={strokeWidth}
          colors={timerColors}
          colorsTime={[
            totalTime,
            totalTime * 0.75,
            totalTime * 0.5,
            totalTime * 0.25,
            totalTime * 0.1,
            0
          ]}
          onUpdate={(time) => {
            if (time < startFrom) socket.emit("updateTime", { id, time });
          }}
          onComplete={() => {
            socket.emit("updateTime", { id, time: 0 });
            onComplete && onComplete();
          }}>
          {renderTime}
        </CountdownCircleTimer>
      </div>
    );
};

export default CountdownTimer;
