import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import { WorkshopContext } from "../contexts/workshop.context";

import { Button, ScreenBottom, StarPicker } from "../components/workshop";
import { Task10, Task4, Task6, Task7 } from "./";

import { defaultPlatforms, defaultRoles, iconsMappingList } from "../util/workshop.util";

import {
  DownloadIcon,
  HeadIcon,
  MagnifierIcon,
  MissionTeamIcon,
  ChMissionFutureIcon,
  FrMissionFutureIcon,
  PersonIcon,
  RadarIcon
} from "../assets/icons";

import logo from "../assets/images/logo.svg";

import summaryIcon from "../assets/images/summaryIcon.png";
import { ProgressBar } from "react-loader-spinner";
import { getInitials } from "../util/general.util";

const iconsMap = {
  magnifier: MagnifierIcon,
  head: HeadIcon,
  person: PersonIcon,
  radar: RadarIcon
};

const mapIconsLocationClasses = {
  0: "left-1 top-1",
  1: "right-1 top-1",
  2: "left-1 bottom-1",
  3: "right-1 bottom-1"
};

const downloadRequested = {
  wordCloud: false,
  communication: false,
  full: false
};

const downloadStarted = {
  wordCloud: false,
  communication: false,
  full: false
};

const pdfTitles = (workshopName, type, fileName) => {
  switch (type) {
    case "wordCloud":
      return `${workshopName} - ${fileName}.pdf`;
    case "communication":
      return `${workshopName} - ${fileName}.pdf`;
    case "full":
      return `${workshopName} - ${fileName}.pdf`;
  }
};

const Summary = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [mapNames, setMapNames] = useState({});

  const [downloading, setDownloading] = useState({
    wordCloud: false,
    communication: false,
    full: false
  });

  const {
    socket,
    user: { role } = {},
    workshopDetails: {
      _id,
      teamName,
      orgPrefix,
      platform,
      inPersonParticipantCode,
      pdf = false,
      pdfSection
    } = {}
  } = useContext(WorkshopContext);

  const downloadFile = (pdfURL, type) => {
    let parsedPdfUrl = pdfURL;
    try {
      parsedPdfUrl = JSON.parse(pdfURL) || pdfURL;
    } catch (error) {
      console.log("parsing of url failed", pdfURL, error);
    }
    // Download as blob from URL
    axios
      .get(parsedPdfUrl, { responseType: "blob" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);

        const pdfTitle = pdfTitles(teamName, type, t(`pdfTitle.${type}`));
        link.download = pdfTitle ? pdfTitle : "team profile.pdf";
        link.click();
      })
      .catch((error) => {
        toast.error("Error downloading PDF");
        console.log("Error downloading PDF", error);
      })
      .finally(() => {
        setDownloading({ ...downloading, [type]: false });
        downloadRequested[type] = false;
        downloadStarted[type] = false;
      });
  };

  const downloadPDF = async (generationType = "teamProfilePdf", pdfSection = "workshop") => {
    try {
      setDownloading({ ...downloading, [generationType]: true });
      downloadRequested[generationType] = true;

      const summaryPageURL = `${window.location.origin}/summary?p=${inPersonParticipantCode}&pdf=true&pdfSection=${pdfSection}`;

      if (window.location.origin.includes("localhost:")) {
        toast.error(
          "PDF generation is not available in development mode. Check logs for summary URL and open it in incognito"
        );
        console.log(summaryPageURL);
        return;
      }

      // Request the existing PDF link and generation status with a callback
      socket.emit(
        "pdfLinkRequest",
        {
          id: _id,
          type: generationType,
          link: summaryPageURL,
          waitIDs: generationType === "full" ? ["#wordcloudDone", "#imageCloudRendering"] : []
        },
        async ({ pdfLink, isGenerating, error }) => {
          if (error) {
            toast.error("Error requesting PDF link");
            throw new Error("Error requesting PDF link", error);
          }

          if (pdfLink && !downloadStarted[generationType]) {
            downloadStarted[generationType] = true;
            downloadFile(pdfLink, generationType);
            return;
          }

          if (isGenerating) {
            console.log("PDF is being generated, please wait...");
            return;
          }
        }
      );
    } catch (error) {
      toast.error("Error generating PDF");
      console.log("Error generating PDF", error);
      setDownloading({ ...downloading, [generationType]: false });
      downloadRequested[generationType] = false;
      downloadStarted[generationType] = false;
    }
  };

  const endWorkshop = () => {
    try {
      socket.emit("endWorkshop");
      navigate("/end");
    } catch (error) {
      console.log("Error ending workshop", error);
    }
  };

  useEffect(() => {
    socket.on("task4", (data) => {
      try {
        if (data) {
          setMapNames(data);
        }
      } catch (error) {
        console.log(error);
      }
    });

    socket.emit("task4");

    socket.on("pdfLinkAvailable", ({ pdfLink, type, isGenerating, error }) => {
      console.log("pdf link available", pdfLink, type, isGenerating, error);
      if (error) {
        toast.error("Error requesting PDF link");
        throw new Error("Error requesting PDF link", error);
      }

      if (pdfLink && downloadRequested[type] && !downloadStarted[type]) {
        downloadStarted[type] = true;
        downloadFile(pdfLink, type);
        return;
      }

      if (isGenerating) {
        console.log("PDF is being generated, please wait...");
        return;
      }
    });
  }, []);

  return (
    <div className={!pdf ? "flex flex-col gap-4" : ""}>
      {!pdf && (
        <>
          <img src={logo} className="block mx-auto w-14 h-14" alt="mission team logo" />

          <h1 className="text-3xl font-bold text-center mb-3">{teamName}</h1>
          <h1
            dangerouslySetInnerHTML={{
              __html: t("mtSummary.heading")
            }}
            className="text-3xl font-bold text-center mb-3 font-righteous text-gray-250"
          />
          <p
            className="text-center"
            dangerouslySetInnerHTML={{
              __html: t("mtSummary.subheading")
            }}></p>

          <span className="border border-orange-100 px-6 py-4 rounded-full w-fit mx-auto text-orange-100">
            {t("mtSummary.teamProfile.buttonText")}
          </span>
        </>
      )}
      {!pdf && (
        <div className="w-full py-10">
          <div
            className={`rounded-2xl bg-white-100 border border-gray-750 p-4 flex flex-col mx-auto ${!pdf ? "max-w-[1000px] mt-6" : "h-fit max-h-[85%]"}`}>
            <img src={summaryIcon} className="absolute left-0 w-auto h-96 top-[20rem] -z-10" />
            <p className="font-bold mb-2">{t("mtSummary.finalDiscussion.title")}</p>
            <ol className="list-decimal list-inside">
              <li>{t("mtSummary.finalDiscussion.question1")}</li>
              <li>{t("mtSummary.finalDiscussion.question2")}</li>
              <li>{t("mtSummary.finalDiscussion.question3")}</li>
            </ol>
          </div>
        </div>
      )}

      {((pdf && (pdfSection === "profile-task10" || pdfSection === "workshop")) || !pdf) && (
        <Section pdf={pdf} platform={platform} centered>
          <div
            className={`rounded-2xl bg-white-100 border border-gray-750 p-2 flex flex-col mx-auto ${!pdf ? "max-w-[1000px] mt-6" : "h-fit max-h-[90%]"}`}>
            <div className={`relative flex flex-wrap gap-0 overflow-hidden`}>
              <img
                src={require(`../assets/images/maps/full.png`)}
                className="block w-full h-full filter brightness-75"
                alt="map"
              />
              {Array.from({ length: 4 }, (_, index) => (
                <div
                  key={index}
                  className={`absolute ${mapIconsLocationClasses[index]} flex gap-1 ${index > 1 ? "flex-col-reverse" : "flex-col"} w-14 sm:w-24`}>
                  <div className={`flex gap-1 p-1.5 bg-white-100 rounded-full`}>
                    {iconsMappingList[index].map((icon, i) => {
                      const Icon = iconsMap[icon];
                      return (
                        <span
                          key={i}
                          className="w-5 h-5 sm:w-10 sm:h-10 bg-gray-200 rounded-full p-1/2 sm:p-2">
                          <Icon />
                        </span>
                      );
                    })}
                  </div>
                  <div
                    className={`flex flex-wrap ${(index + 1) % 2 !== 0 ? "justify-start" : "justify-end"}`}>
                    {mapNames[index] !== undefined && (
                      <div
                        className={`flex w-4/5 sm:w-full flex-wrap sm:p-1 ${(index + 1) % 2 !== 0 ? "justify-start" : "justify-end"}`}>
                        {mapNames[index].map(({ name, symbolIndex }, symIndex) => {
                          return (
                            <div className="w-full sm:w-1/2">
                              <Symbol
                                key={symbolIndex + "-" + symIndex}
                                name={name}
                                symbolIndex={symbolIndex}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              ))}
              <div className="absolute left-0 top-0 bottom-0 right-0 flex items-center justify-center w-full h-full">
                <Task10 summary={true} pdf={pdf} />
              </div>
              {/* <div
              className={`absolute transform top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}>
            </div> */}
            </div>
            <BottomSection
              orgPrefix={orgPrefix}
              platform={platform}
              teamName={teamName}
              tiles={[
                { title: t("mtSummary.teamProfile.tags.tag1"), color: "text-purple-100" },
                { title: t("mtSummary.teamProfile.tags.tag2"), color: "text-green-100" },
                { title: t("mtSummary.teamProfile.tags.tag3"), color: "text-orange-100" }
              ]}
              buttonTitle={t("mtSummary.teamProfile.buttonText")}
              onClick={() => downloadPDF("wordCloud", "profile-task10")}
              pdf={pdf}
              Icon={DownloadIcon}
              loading={downloading.wordCloud}
            />
          </div>
        </Section>
      )}

      {((pdf && (pdfSection === "profile-task8" || pdfSection === "workshop")) || !pdf) && (
        <Section pdf={pdf} platform={platform} centered>
          <div
            className={`rounded-2xl bg-white-100 border border-gray-750 p-2 flex flex-col mx-auto ${!pdf ? "max-w-full mt-6" : "h-auto justify-between"}`}>
            <div className={`relative flex flex-wrap gap-0 overflow-hidden`}>
              <StarPicker
                socket={socket}
                id={"task8"}
                type={"b"}
                userId={1}
                summary={true}
                pdf={pdf}
              />
            </div>

            <BottomSection
              orgPrefix={orgPrefix}
              platform={platform}
              teamName={teamName}
              tiles={[
                {
                  title: t("mtSummary.teamCommunicationPreference.tags.tag1"),
                  color: "text-green-100"
                },
                {
                  title: t("mtSummary.teamCommunicationPreference.tags.tag2"),
                  color: "text-blue-400"
                }
              ]}
              buttonTitle={t("mtSummary.teamCommunicationPreference.buttonText")}
              onClick={() => downloadPDF("communication", "profile-task8")}
              pdf={pdf}
              Icon={DownloadIcon}
              loading={downloading.communication}
            />
          </div>
        </Section>
      )}

      {!pdf && (
        <section>
          <div className="flex items-center justify-center my-10">
            <span className="border border-orange-100 px-6 py-4 rounded-full w-fit mx-auto text-orange-100">
              {t("mtSummary.workshop")}
            </span>
          </div>
        </section>
      )}

      {((pdf && pdfSection === "workshop") || !pdf) && (
        <Section pdf={pdf} platform={platform} centered>
          <div
            className={`rounded-2xl bg-white-100 border border-gray-750 p-2 flex flex-col mx-auto ${!pdf ? "max-w-[1000px] mt-6" : "w-fit h-full max-h-[90%]"}`}>
            <div className={`relative flex flex-wrap gap-0 overflow-hidden`}>
              <div className={`w-full h-full pointer-events-none`}>
                <Task4 summary={true} pdf={pdf} />
              </div>
            </div>

            <BottomSection
              orgPrefix={orgPrefix}
              platform={platform}
              teamName={teamName}
              tiles={[{ title: t("mtSummary.teamMapping.tags.tag1"), color: "text-orange-100" }]}
              buttonTitle={t("mtSummary.teamMapping.buttonText")}
              pdf={pdf}
            />
          </div>
        </Section>
      )}

      {((pdf && pdfSection === "workshop") || !pdf) && (
        <Section pdf={pdf} platform={platform} centered>
          <div
            className={`rounded-2xl bg-white-100 border border-gray-750 p-2 flex flex-col mx-auto h-full w-full ${!pdf ? "max-w-[1000px] mt-6" : "w-full max-h-[85%]"}`}>
            <div className={`relative flex flex-wrap gap-0 overflow-hidden h-full w-full`}>
              <div className={`w-full h-full`}>
                <Task6 summary={true} pdf={pdf} />
              </div>
            </div>

            <BottomSection
              orgPrefix={orgPrefix}
              platform={platform}
              teamName={teamName}
              tiles={[
                { title: t("mtSummary.teamStrengthCloud.tags.tag1"), color: "text-orange-100" }
              ]}
              buttonTitle={t("mtSummary.teamStrengthCloud.buttonText")}
              pdf={pdf}
            />
          </div>
        </Section>
      )}

      {((pdf && pdfSection === "workshop") || !pdf) && (
        <Section pdf={pdf} platform={platform} centered>
          <div
            className={`rounded-2xl bg-white-100 border border-gray-750 p-2 flex flex-col mx-auto ${!pdf ? "max-w-[1000px] w-full mt-6" : "w-full h-fit max-h-[85%]"}`}>
            <div className={`relative flex flex-wrap gap-0 overflow-hidden`}>
              <div className={`w-full h-full`}>
                <Task7 summary={true} pdf={true} />
              </div>
            </div>
            <BottomSection
              orgPrefix={orgPrefix}
              platform={platform}
              teamName={teamName}
              tiles={[{ title: t("mtSummary.teamRolesCloud.tags.tag1"), color: "text-orange-100" }]}
              buttonTitle={t("mtSummary.teamRolesCloud.buttonText")}
              pdf={pdf}
            />
          </div>
        </Section>
      )}

      {!pdf && (
        <ScreenBottom>
          <div className="flex flex-col gap-2 w-full max-w-96 mt-8 mx-auto">
            <Button
              onClick={() => downloadPDF("full", "workshop")}
              disabled={downloading.full}
              loading={downloading.full}>
              <span className="flex justify-center items-center">
                {!downloading.full && (
                  <div className="w-10 h-10">
                    <DownloadIcon color="#fff" />
                  </div>
                )}
                <span
                  dangerouslySetInnerHTML={{ __html: t("mtSummary.button") }}
                  className="ml-2"
                />
              </span>
            </Button>
            {role !== defaultRoles.participant && (
              <Button text={t("mtSummary.end")} type="outline" onClick={endWorkshop} />
            )}
          </div>
        </ScreenBottom>
      )}
    </div>
  );
};

const Section = ({ pageNumber, pdf, platform, teamName, children, className, centered }) => {
  if (pdf)
    return (
      <div
        className={`relative h-screen px-6 py-6 ${className} ${centered ? "flex items-center justify-center" : ""}`}>
        {children}
        {pageNumber && (
          <span className="absolute right-0 bottom-0 text-base text-black-200">{pageNumber}</span>
        )}
      </div>
    );
  else return <>{children}</>;
};

const BottomSection = ({
  orgPrefix,
  platform,
  teamName,
  tiles,
  pdf = false,
  buttonTitle,
  onClick,
  Icon,
  loading
}) => (
  <>
    {pdf && (
      <div className="mt-8 w-full flex items-center justify-between">
        <div className="flex items-center gap-2">
          <span className="h-14">
            {[defaultPlatforms.mt, defaultPlatforms.mtOnline].includes(platform) ? (
              <MissionTeamIcon />
            ) : orgPrefix === "fr" ? (
              <FrMissionFutureIcon />
            ) : (
              <ChMissionFutureIcon />
            )}
          </span>
          <span className="text-2xl font-bold">{teamName}</span>
        </div>
        <div className="flex items-center gap-8">
          {tiles.map((item) => (
            <span
              key={item.title}
              className={`"rounded bg-orange-200 px-6 py-1 font-bold tracking-wide ${item.color}`}
              dangerouslySetInnerHTML={{ __html: item.title }}
            />
          ))}
        </div>
      </div>
    )}
    {!pdf && (
      <Button
        type="secondary"
        onClick={onClick}
        className={`!bg-transparent self-end !w-fit ${!onClick || loading ? "pointer-events-none" : ""}`}>
        {
          <>
            {loading && (
              <span className="w-6 h-6">
                <ProgressBar
                  visible={true}
                  barColor="#000"
                  ariaLabel="button-loading"
                  wrapperStyle={{}}
                  wrapperClass="bars-wrapper"
                />
              </span>
            )}
          </>
        }
        {Icon && !loading && <Icon />}
        <p>{buttonTitle}</p>
      </Button>
    )}
  </>
);

export const Symbol = ({ name, symbolIndex, onClick }) => {
  return (
    <span
      className={`relative z-10 w-10 h-10 p-2 rounded-full ${symbolIndex === 0 ? "bg-orange-100 text-white-100" : "bg-white-100 text-black-100 border-orange-100 border-2"} font-bold flex items-center justify-center`}
      onClick={onClick}>
      <span className="text-sm uppercase">
        {getInitials(name) || `${name?.charAt(0)}${name?.charAt(1)}`}
      </span>
    </span>
  );
};

export default Summary;
