import React from "react";
import { ThreeCircles } from "react-loader-spinner";

const DefaultLoader = ({ message = "" }) => {
  return (
    <div
      id="loading"
      className="fixed top-0 left-0 h-screen w-full flex items-center justify-center flex-row z-[1000] bg-white-100">
      <div className="text-xl text-black flex gap-2 items-center flex-col">
        <ThreeCircles
          visible={true}
          height="80"
          width="80"
          color="#e59536"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
        />
        <span className="block">{message}</span>
      </div>
    </div>
  );
};

export default DefaultLoader;
