import React from "react";

const ProgressBar = ({ total, progress, defaultColor, progressColor }) => {
  const progressPercentage = (progress / total) * 100;

  return (
    <div style={{ backgroundColor: defaultColor }} className="w-full h-3 rounded-2xl">
      <div
        className="h-full rounded-2xl"
        style={{
          width: `${progressPercentage}%`,
          backgroundColor: progressColor
        }}
      />
    </div>
  );
};

export default ProgressBar;
