export const hexToRgba = (hex, opacity) => {
  // Remove the '#' symbol from the hex code
  hex = hex.replace("#", "");

  // Convert the hex code to RGB values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate the opacity value
  const alpha = opacity / 100;

  // Return the RGBA value
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
