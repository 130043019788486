import React, { useContext } from "react";
import QRCode from "react-qr-code";

import { WorkshopContext } from "../../contexts/workshop.context";

const ParticipantsJoinQR = () => {
  const { workshopDetails: { inPersonParticipantCode } = {} } = useContext(WorkshopContext);

  return (
    <div className="w-full sm:max-w-80 mx-auto">
      <QRCode
        value={`${window.location.origin}?p=${inPersonParticipantCode}`}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
      />
    </div>
  );
};

export default ParticipantsJoinQR;
