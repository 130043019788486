import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Accordion, StatsChart, WordCloudRenderer } from "./index";
import { BulbIcon } from "../../assets/icons";
import { Participant, Popup } from "../workshop";
import { mapSelectionsToUsers } from "../../util/insights.util";
import { capitalizeFirstLetter } from "../../util/translations.util";

const TeamStrengths = ({
  strengths,
  users,
  strengthsSubmissions,
  isPdf,
  platform,
  showStats = true
}) => {
  const { t } = useTranslation();

  const [strengthsList, setStrengthsList] = useState([]);
  const [wordUserSelection, setWordUserSelections] = useState({});
  const [selectedWord, setSelectedWord] = useState({
    word: "",
    users: []
  });
  const [strengthsStats, setStrengthsStats] = useState([]);

  const Strengths = t("resources.strengths", { returnObjects: true });
  const translationPrefix = platform === "mft" ? "mft.strengths" : "strengths";
  const teamStrengths = t(translationPrefix, { returnObjects: true });
  const { chosen } = teamStrengths?.popup || {};

  const displayWordDetails = (word) => {
    // Find this word in our list or workshop lang and get index
    const wordIndex = Strengths?.findIndex((w) =>
      w?.title?.toLowerCase()?.includes(word?.toLowerCase())
    );

    setSelectedWord({
      index: wordIndex,
      word: word,
      users: wordUserSelection[word],
      image: Strengths?.[wordIndex]?.noCaption,
      description: Strengths?.[wordIndex]?.description
    });
  };

  const content = () => {
    const { description, tips = [] } = teamStrengths?.content || {};
    return (
      <div className="block w-full">
        {!isPdf && <p className="mb-4" dangerouslySetInnerHTML={{ __html: description }} />}

        <div
          className={`rounded-lg border-2 border-gray-250/40 bg-[#FFF] flex items-center gap-3 ${
            isPdf ? "px-4 py-2" : "p-4"
          }`}>
          <span className="w-12 h-12">
            <BulbIcon />
          </span>
          <ul className={`list-disc pl-6 ${isPdf ? "text-xs" : ""}`}>
            {tips.map((tip, index) => (
              <li
                key={index}
                className="text-[#575756]"
                dangerouslySetInnerHTML={{ __html: tip }}
              />
            ))}
          </ul>
        </div>

        <div className={`w-full mt-4 ${isPdf ? "bg-orange-200 py-4 px-52 rounded-2xl" : ""}`}>
          <WordCloudRenderer
            id={"strengths"}
            words={strengthsList}
            wordClickCallback={displayWordDetails}
            isPdf={isPdf}
            width={1500}
            height={1500}
          />
        </div>

        {!isPdf && showStats && (
          <div className="rounded-lg border-2 border-gray-250/40 bg-[#FFF] p-6 mt-6">
            <StatsChart data={strengthsStats} />
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    try {
      if (strengths) {
        let temp = [];
        for (let key in strengths) {
          temp.push([capitalizeFirstLetter(key), strengths[key]]);
        }
        setStrengthsList(temp);
        setWordUserSelections(mapSelectionsToUsers(users, strengthsSubmissions));
      }
    } catch (error) {
      console.error("Error in Insights strengths", error);
    }
  }, [strengths, users, strengthsSubmissions]);

  useEffect(() => {
    if (isPdf || !strengthsList?.length) return;

    const temp = [];

    try {
      strengthsList.forEach((strength) => {
        temp.push({
          text: strength?.[0],
          value: strength?.[1],
          color: "#E59536"
        });
      });

      setStrengthsStats(temp);
    } catch (error) {
      console.error("Error in TeamStrengths", error);
    }
  }, [isPdf, strengthsList]);

  return (
    <div
      id="strengths"
      className={
        isPdf ? "h-screen px-4 flex flex-col justify-start items-center overflow-hidden" : "mb-12"
      }>
      <Accordion
        title={teamStrengths?.title}
        icon={teamStrengths?.icon}
        content={content()}
        isPdf={isPdf}
      />

      <Popup
        open={selectedWord?.word && selectedWord?.users}
        weightFactorFormula={(size) => (isPdf ? size * 0.7 : size)}
        setOpen={setSelectedWord}
        autoHeight={true}
        maxWidth="sm:max-w-3xl"
        noSpacePopup={true}
        mobilePopupStyle={false}>
        <div className="w-full flex flex-col sm:flex-row gap-3">
          <div
            className="w-full sm:w-[30%] h-60 sm:h-auto bg-cover bg-center"
            style={{
              backgroundImage: `url(${selectedWord?.image})`,
              backgroundRepeat: "no-repeat"
            }}
          />
          <div className="w-full sm:w-[70%] pl-2 pr-4 py-4">
            <div className="flex gap-2 items-center justify-start flex-wrap w-full mb-6">
              <span
                className="text-[#454444] text-2xl font-bold uppercase"
                dangerouslySetInnerHTML={{ __html: chosen }}
              />
              <div className="flex gap-2 flex-wrap">
                {selectedWord?.users?.map((user, index) => (
                  <Participant key={index} name={user} showFullName={false} />
                ))}
              </div>
            </div>
            <h2 className="text-3xl mb-4 font-gochi text-orange-100">{selectedWord.word}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: selectedWord?.description
              }}
            />
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default TeamStrengths;
