import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { WorkshopContext } from "../contexts/workshop.context";

import {
  ScreenBottom,
  Button,
  ParticipantsList,
  CountdownTimer,
  ParticipantWithTimers,
  ModeratorParticipantWithTimers
} from "../components/workshop";

import { defaultPlatforms, defaultRoles } from "../util/workshop.util";
import { htmlToText } from "../util/translations.util";

const TASK_ID = 2;

const Task5 = () => {
  const [allGroups, setAllGroups] = useState([]);
  const [groupTaskStatus, setGroupTaskStatus] = useState({});
  const [groupId, setGroupId] = useState(undefined);
  const [userGroup, setUserGroup] = useState([]);
  const [startTask, setStartTask] = useState(false);
  const [finishedTask, setFinishedTask] = useState(false);
  const [task5Status, setTask5Status] = useState({
    currentParticipantIndex: 0,
    currentTimerIndex: 0
  });
  const [timerStates, setTimerStates] = useState({});

  const {
    socket,
    user: { id, role } = {},
    workshopDetails: { platform } = {}
  } = useContext(WorkshopContext);

  const { t } = useTranslation();

  const baseUrl = platform === defaultPlatforms.mtOnline ? "/online" : "";

  const isMtOnline = platform === defaultPlatforms.mtOnline;

  const handleTimerComplete = (participantIndex, timerIndex) => {
    if (isMtOnline) {
      let nextParticipantIndex = participantIndex;
      let nextTimerIndex = timerIndex;

      if (timerIndex > 1) {
        nextParticipantIndex++;
        nextTimerIndex = 0;
      }

      socket.emit("updateTask5Status", {
        currentParticipantIndex: nextParticipantIndex,
        currentTimerIndex: nextTimerIndex
      });

      handleTimerUpdate(groupId, participantIndex, timerIndex - 1, 0);
    }
  };

  const handleTimerUpdate = (groupIndex, participantIndex, timerIndex, time) => {
    setTimerStates((prevStates) => {
      const newStates = {
        ...prevStates,
        [`${groupIndex}_${participantIndex}_${timerIndex}`]: { time, finished: time === 0 }
      };

      // Check if all timers are completed for the current participant
      const participantFinished =
        newStates[`${groupIndex}_${participantIndex}_0`]?.finished &&
        newStates[`${groupIndex}_${participantIndex}_1`]?.finished;

      if (participantFinished) {
        // Update the next participant's timer if available
        const nextParticipantIndex = participantIndex + 1;
        if (nextParticipantIndex < userGroup.length) {
          newStates[`${groupIndex}_${nextParticipantIndex}_0`] = {
            time: selectTimerTime(0),
            finished: false
          };
        }
      }

      // Check if all timers are completed for the current group
      const allTimersCompleted = userGroup.every(
        (_, pIndex) =>
          newStates[`${groupIndex}_${pIndex}_0`]?.finished &&
          newStates[`${groupIndex}_${pIndex}_1`]?.finished
      );

      if (allTimersCompleted && !groupTaskStatus[groupIndex]?.finished) {
        socket.emit("updateGroupTaskStatus", {
          taskId: TASK_ID,
          groupId: groupIndex,
          started: true,
          finished: true
        });
      }

      return newStates;
    });
  };

  useEffect(() => {
    if (groupId === undefined)
      socket.emit(
        "userGroupForTask",
        { taskId: TASK_ID, userId: id },
        ({ groupIndex, userGroup }) => {
          if (groupIndex !== undefined) setGroupId(groupIndex);
          if (userGroup) setUserGroup(userGroup);
        }
      );

    socket.emit("allGroupsOfTask", TASK_ID, (groups) => {
      if (groups) setAllGroups(groups);
    });

    socket.emit("groupTaskStatus", TASK_ID, (status) => {
      if (status) setGroupTaskStatus(status);

      if (groupId === undefined || groupId < 0) return;

      if (status?.[groupId]?.started) setStartTask(true);

      if (status?.[groupId]?.finished) setFinishedTask(true);
    });

    socket.on("groupTaskStatus", (status) => {
      setGroupTaskStatus(status?.[TASK_ID]);

      if (groupId === undefined || groupId < 0) return;

      if (status?.[TASK_ID]?.[groupId]?.started) setStartTask(true);

      if (status?.[TASK_ID]?.[groupId]?.finished) setFinishedTask(true);
    });

    if (isMtOnline) {
      socket.emit("getTask5Status", (status) => {
        setTask5Status(status);
      });

      socket.on("task5StatusUpdated", (status) => {
        setTask5Status(status);
      });

      socket.on("timeUpdated", ({ id, time }) => {
        const [taskId, groupIndex, fullIndex] = id.split("_");
        if (taskId === TASK_ID.toString()) {
          const participantIndex = Math.floor(fullIndex / 2);
          const timerIndex = fullIndex % 2;
          handleTimerUpdate(groupIndex, participantIndex, timerIndex, parseInt(time));
        }
      });
    }

    return () => {
      if (isMtOnline) {
        socket.off("task5StatusUpdated");
        socket.off("timeUpdated");
      }
    };
  }, [id, role, groupId, isMtOnline]);

  return (
    <div className="flex flex-col gap-4">
      {role !== defaultRoles.moderator && (
        <h1
          dangerouslySetInnerHTML={{ __html: t("mtParticipantActivityScreen.headingFour") }}
          className="text-5xl font-medium font-righteous text-gray-250"
        />
      )}

      {role === defaultRoles.participant && !startTask && (
        <p
          dangerouslySetInnerHTML={{ __html: t("mtParticipantPreActivity.subheadingTwo") }}
          className="text-xl text-gray-100"
        />
      )}
      {role === defaultRoles.moderator && !startTask && (
        <p
          dangerouslySetInnerHTML={{ __html: t("mtParticipantPreActivity.subheadingTwoMod") }}
          className="text-xl text-gray-100"
        />
      )}
      {role === defaultRoles.moderatorAndParticipant && !startTask && (
        <p
          dangerouslySetInnerHTML={{ __html: t("mtParticipantPreActivity.subheadingTwoModPart") }}
          className="text-xl text-gray-100"
        />
      )}

      {role !== defaultRoles.moderator && startTask && (
        <>
          <p
            dangerouslySetInnerHTML={{
              __html:
                userGroup.length === 2
                  ? t("mtParticipantActivityScreen.paragraphFour")
                  : t("mtParticipantActivityScreen.paragraphSeven")
            }}
            className="text-xl text-gray-100"
          />
        </>
      )}

      {role === defaultRoles.moderator && (
        <>
          <h1
            dangerouslySetInnerHTML={{ __html: t("listOfGroups.heading") }}
            className="text-2xl font-bold"
          />
          {/* <p
            dangerouslySetInnerHTML={{ __html: t("listOfGroups.subHeading") }}
            className="text-xl text-gray-100"
          /> */}
        </>
      )}

      {role !== defaultRoles.moderator && !startTask && (
        <ParticipantsList
          heading={t("mtParticipantPreActivity.listHeading")}
          participants={userGroup}
        />
      )}

      {role !== defaultRoles.moderator && startTask && (
        <>
          {isMtOnline ? (
            <>
              {(role === defaultRoles.participant ||
                (role === defaultRoles.moderatorAndParticipant && !finishedTask)) && (
                <div className="flex flex-col gap-4">
                  {userGroup.map((participant, index) => (
                    <ParticipantWithTimers
                      key={participant.id}
                      participant={participant}
                      groupIndex={groupId}
                      participantIndex={index}
                      start={startTask}
                      finished={groupTaskStatus?.[groupId]?.finished}
                      role={role}
                      currentParticipantIndex={task5Status.currentParticipantIndex}
                      currentTimerIndex={task5Status.currentTimerIndex}
                      onTimerComplete={handleTimerComplete}
                      onTimerUpdate={handleTimerUpdate}
                    />
                  ))}
                </div>
              )}
            </>
          ) : (
            <>
              {(role === defaultRoles.participant ||
                (role === defaultRoles.moderatorAndParticipant && !finishedTask)) && (
                <>
                  <ParticipantsList
                    heading={t("mtParticipantActivityScreen.listHeading")}
                    participants={userGroup}
                  />
                  <Timers
                    groupLength={userGroup.length}
                    groupIndex={groupId}
                    start={startTask}
                    finished={groupTaskStatus?.[groupId]?.finished}
                    role={role}
                  />
                </>
              )}
            </>
          )}
        </>
      )}

      {(role === defaultRoles.moderator ||
        (role === defaultRoles.moderatorAndParticipant && finishedTask)) && (
        <div className="flex flex-col gap-5">
          {allGroups.map((group, groupIndex) =>
            isMtOnline ? (
              group.map((participant, participantIndex) => (
                <ModeratorParticipantWithTimers
                  key={participant.id}
                  participant={participant}
                  groupIndex={groupIndex}
                  participantIndex={participantIndex}
                  start={groupTaskStatus?.[groupIndex]?.started}
                  finished={groupTaskStatus?.[groupIndex]?.finished}
                  currentParticipantIndex={task5Status.currentParticipantIndex}
                  currentTimerIndex={task5Status.currentTimerIndex}
                  onTimerComplete={handleTimerComplete}
                  timerStates={timerStates}
                />
              ))
            ) : (
              <div key={groupIndex} className="bg-white-100 p-4 rounded-lg border border-black-100">
                <ParticipantsList
                  heading={`${t("listOfGroups.group")} ${groupIndex + 1}`}
                  completedText={t("task.completed")}
                  participants={group}
                  started={groupTaskStatus?.[groupIndex]?.started}
                  finished={groupTaskStatus?.[groupIndex]?.finished}
                />
                <Timers
                  groupLength={group.length}
                  groupIndex={groupIndex}
                  start={groupTaskStatus?.[groupIndex]?.started}
                  finished={groupTaskStatus?.[groupIndex]?.finished}
                  greyedOnCompletion
                  role={role}
                />
              </div>
            )
          )}
        </div>
      )}

      <ScreenBottom>
        <div className="sm:w-96 w-full">
          {role === defaultRoles.moderator && (
            <div className="flex flex-row gap-2">
              <Button
                text={t("buttonBack")}
                onClick={() => socket.emit("navigate", `${baseUrl}/video/5`)}
                type="outline"
              />
              <Button
                text={t("buttonNext")}
                onClick={() => socket.emit("navigate", `${baseUrl}/video/6`)}
              />
            </div>
          )}
          {role !== defaultRoles.moderator && (
            <div className="flex flex-col gap-2">
              {!finishedTask && !startTask && (
                <Button
                  text={t("buttonStart")}
                  onClick={() => {
                    socket.emit("updateGroupTaskStatus", {
                      taskId: TASK_ID,
                      groupId,
                      started: true,
                      finished: false
                    });
                  }}
                />
              )}
              {startTask && !finishedTask && (
                <Button
                  text={t("buttonComplete")}
                  onClick={() => {
                    socket.emit("updateGroupTaskStatus", {
                      taskId: TASK_ID,
                      groupId,
                      started: true,
                      finished: true
                    });
                  }}
                />
              )}
              {role !== defaultRoles.participant && (startTask || finishedTask) && (
                <Button
                  text={t("buttonNext")}
                  onClick={() => {
                    socket.emit("navigate", `${baseUrl}/video/6`);
                  }}
                />
              )}
            </div>
          )}
        </div>
      </ScreenBottom>
    </div>
  );
};

const Timers = ({
  start = false,
  finished,
  groupLength,
  groupIndex,
  greyedOnCompletion = false,
  role
}) => {
  const { t } = useTranslation();

  const [timerIndex, setTimerIndex] = useState(0);

  const { socket } = useContext(WorkshopContext);

  const selectTimerTime = (index) => {
    const firstTimer = groupLength === 2 ? 180 : 120;
    const secondTimer = groupLength === 2 ? 90 : 60;
    if (index % 2 === 0) return firstTimer;
    else return secondTimer;
  };

  return (
    <div className={`grid grid-cols-2 md:grid-cols-2 lg:grid-cols-6 gap-2 my-4`}>
      {groupLength > 0 &&
        [...Array(groupLength * 2)].map((_, index) => {
          return (
            <div
              key={index}
              className={`flex justify-center items-center flex-col bg-white-100 p-4 rounded-2xl border border-gray-250`}>
              <CountdownTimer
                id={`${TASK_ID}_${groupIndex}_${index}`}
                totalTime={selectTimerTime(index)}
                start={start && timerIndex === index}
                finished={timerIndex > index || finished}
                greyedOnCompletion={greyedOnCompletion}
                onComplete={() => {
                  if (index === groupLength * 2 - 1)
                    socket.emit("updateGroupTaskStatus", {
                      taskId: TASK_ID,
                      groupId: groupIndex,
                      started: true,
                      finished: true
                    });
                  setTimerIndex(index + 1);
                }}
              />
              <p
                className={`text-sm text-center mt-2 ${greyedOnCompletion && finished ? "text-[#cccccc]" : "text-black-100"}`}
                dangerouslySetInnerHTML={{
                  __html:
                    index % 2 === 0
                      ? `${htmlToText(t("mtActivityFive.personOne")).split(" ")[0]} ${Math.ceil(index / 2 + 1)}`
                      : t("mtActivityFive.feedback")
                }}
              />
              {role !== defaultRoles.moderator && (
                <Button
                  text={t("buttonComplete")}
                  disabled={timerIndex > index || finished}
                  className={`text-sm sm:text-sm !py-2 !px-1 mt-1 min-w-fit`}
                  onClick={() => {
                    socket.emit("updateTime", { id: `${TASK_ID}_${groupIndex}_${index}`, time: 0 });
                    if (timerIndex > index) return;
                    if (index === groupLength * 2 - 1)
                      socket.emit("updateGroupTaskStatus", {
                        taskId: TASK_ID,
                        groupId: groupIndex,
                        started: true,
                        finished: true
                      });
                    setTimerIndex(index + 1);
                  }}
                />
              )}
            </div>
          );
        })}
    </div>
  );
};

const selectTimerTime = (index) => {
  return index % 2 === 0 ? 180 : 90;
};

export default Task5;
