import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Popup from "./popup";
import { ParticipantJoinQR } from ".";

import logo from "../../assets/images/logo.svg";

const QRPopup = ({ floating = true, teamName, iconFullImage = true }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        className={`w-full left-0 bottom-32 z-40 px-6 bg-orange-50 ${floating ? "fixed" : "z-0"}`}>
        <div className="w-full max-w-[1000px] flex items-center justify-center mx-auto px-0 sm:px-6 py-2">
          <button
            className={`block ${!iconFullImage ? "w-12 h-12 bg-white-100 rounded-full" : "w-full sm:max-w-80"}`}
            onClick={() => setOpen(!open)}>
            {iconFullImage ? (
              <img
                src={t("afterJoiningPopup.image")}
                alt="join workshop"
                className="w-full h-auto block mx-auto"
              />
            ) : (
              <img src={logo} className="block w-full h-full" alt="logo" />
            )}
          </button>
        </div>
      </div>

      <Popup open={open} setOpen={setOpen} bottomPadding={false} autoHeight={true}>
        <div className="flex flex-col gap-4">
          <div className="flex gap-4 items-center justify-center">
            {/* <img src={logo} className="w-12 h-12" alt="logo" /> */}
            <h3
              className="font-medium text-orange-100 text-center font-righteous text-5xl"
              dangerouslySetInnerHTML={{ __html: teamName }}
            />
          </div>
          <p
            className="text-xl text-center text-gray-100"
            dangerouslySetInnerHTML={{ __html: t("qrcodePopup.info") }}
          />

          <div className="w-full mx-auto pb-4">
            <ParticipantJoinQR />
          </div>
        </div>
      </Popup>
    </>
  );
};

export default QRPopup;
