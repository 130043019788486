import React from "react";

import { useTranslation } from "react-i18next";

const endImage = require("../assets/images/end.png");

const End = () => {
  const { t } = useTranslation();

  return (
    <div className="fixed top-0 left-0 flex flex-col gap-2 items-center justify-center h-screen w-full">
      <img src={endImage} alt="End of workshop" className="w-96 max-w-full h-auto" />
      <h1
        className="font-bold text-2xl font-righteous text-gray-250"
        dangerouslySetInnerHTML={{ __html: t("end") }}
      />
      <p className="text-center mt-4" dangerouslySetInnerHTML={{ __html: t("endCaption") }} />
      <p
        className="absolute bottom-10 text-orange-100"
        dangerouslySetInnerHTML={{ __html: t("endSupport") }}
      />
    </div>
  );
};

export default End;
