import logo from "../../assets/images/logoFull.svg";
import logoMft from "../../assets/images/mftLogoFull.svg";

const Footer = ({ platform = "mt" }) => {
  const getLogo = () => (platform === "mft" ? logoMft : logo);

  const getCopyright = () => {
    if (platform === "mft") {
      return <>&copy; PSYfiers&reg; {new Date().getFullYear()}</>;
    }
    return <>&copy; {new Date().getFullYear()} Mission Team, Inc. All rights reserved.</>;
  };

  return (
    <div className={`py-8 px-6 w-full mt-12 border-t border-gray-100/30`}>
      <img className="h-20 block mb-8" src={getLogo()} alt="logo" />
      <span className="text-white sm:mr-4">{getCopyright()}</span>
    </div>
  );
};

export default Footer;
