import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { BulbIcon } from "../../assets/icons";
import { Accordion, CommunicationMap, StatsChart } from ".";

const TeamCommunicationPreferences = ({
  starSelections = {},
  attitudesSelections: { wordScores, modSelections } = {},
  isPdf = false,
  platform,
  showStats = true
}) => {
  const { t, i18n } = useTranslation();

  const [communicationStats, setCommunicationStats] = useState([]);
  const [attitudesStats, setAttitudesStats] = useState([]);

  const attitudesList = t("resources.attitudes", {
    returnObjects: true
  });
  const starsList = t("resources.starsList", { returnObjects: true });

  const translation =
    platform === "mft" ? "mft.communicationPreferences" : "communicationPreferences";

  const teamCommunicationPreferences = t(translation, {
    returnObjects: true
  });

  const selectedAttitudes = Object.keys(modSelections || {})
    .filter((key) => modSelections[key])
    .map((key) => attitudesList[parseInt(key)])
    .filter(Boolean)
    .sort((a, b) => a.localeCompare(b, i18n.language));

  const content = () => {
    const { description, tips = [] } = teamCommunicationPreferences?.content || {};
    return (
      <div className="block w-full">
        {!isPdf && <p className="mb-4" dangerouslySetInnerHTML={{ __html: description }} />}

        {isPdf && (
          <div
            className={`flex items-center justify-between cursor-pointer select-none ${isPdf ? "mb-2" : "mb-6"}`}>
            <div className="flex items-center gap-2">
              <span
                data-testid="accordion-icon"
                className={`block ${isPdf ? "w-10 h-10" : "w-16 h-16"}`}
                dangerouslySetInnerHTML={{ __html: teamCommunicationPreferences?.icon }}
              />
              <div
                className="text-gray-250 text-xl font-extrabold"
                dangerouslySetInnerHTML={{ __html: teamCommunicationPreferences?.title }}
              />
            </div>
            <div className="flex items-center gap-2">
              {teamCommunicationPreferences?.pdf?.mapKeys?.map((wordCloudKey) => (
                <span
                  key={wordCloudKey}
                  className="bg-orange-200 px-6 py-1 rounded-sm min-w-24 text-center"
                  dangerouslySetInnerHTML={{ __html: wordCloudKey }}
                />
              ))}
            </div>
          </div>
        )}

        <div
          className={`rounded-lg border-2 border-gray-250/40 bg-[#FFF] flex items-center gap-3 ${
            isPdf ? "px-4 py-2" : "p-4"
          }`}>
          <span className="w-12 h-12">
            <BulbIcon />
          </span>
          <ul className={`list-disc pl-6 ${isPdf ? "text-xs" : ""}`}>
            {tips.map((tip, index) => (
              <li
                key={index}
                className="text-[#575756]"
                dangerouslySetInnerHTML={{ __html: tip }}
              />
            ))}
          </ul>
        </div>

        <div className={`w-full ${isPdf ? "bg-orange-200 p-2 rounded-2xl mt-2" : "mt-4"}`}>
          <CommunicationMap starSelections={starSelections} isPdf={isPdf} platform={platform} />
        </div>

        {!isPdf && showStats && (
          <div className="rounded-lg border-2 border-gray-250/40 bg-[#FFF] p-6 mt-6">
            <StatsChart data={communicationStats} halfCol={true} />
          </div>
        )}

        {!isPdf && selectedAttitudes?.length > 0 && showStats && (
          <div className="flex flex-col items-center mt-6">
            <p
              className="mb-4"
              dangerouslySetInnerHTML={{ __html: teamCommunicationPreferences?.attitudes?.heading }}
            />
            <div className="flex flex-wrap gap-2">
              {selectedAttitudes.map((attitude) => (
                <span
                  key={attitude}
                  className="bg-orange-100 px-6 py-1 rounded-lg text-white-100 min-w-36 text-center">
                  {attitude}
                </span>
              ))}
            </div>
          </div>
        )}

        {!isPdf && !attitudesStats && showStats && (
          <div className="rounded-lg border-2 border-gray-250/40 bg-[#FFF] p-6 mt-6">
            <StatsChart data={attitudesStats} />
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (isPdf || !wordScores) return;

    const temp = [];

    try {
      Object.keys(wordScores).forEach((wordKey, index) => {
        const score = wordScores[wordKey];
        temp.push({
          text: attitudesList[wordKey],
          value: score,
          color: modSelections[wordKey] ? "#E59536" : "#FFEDD1",
          textColor: modSelections[wordKey] ? "#FFF" : "#E59536"
        });
      });

      setAttitudesStats(temp);
    } catch (error) {
      console.error("Error in TeamCommunicationPreferences", error);
    }
  }, [isPdf, wordScores, modSelections]);

  useEffect(() => {
    if (isPdf || !starsList || !starSelections) return;

    const temp = [];

    try {
      Object.keys(starSelections).forEach((starType) => {
        const starTypeSelections = starSelections[starType];

        Object.keys(starTypeSelections).forEach((starColumn) => {
          const starColumnSelections = starTypeSelections[starColumn];

          Object.keys(starColumnSelections).forEach((starKey) => {
            const starKeySelections = starColumnSelections[starKey];

            if (starKeySelections?.length > 0 && starType === "a") {
              const existing = temp.find((item) => item.text === starsList[starColumn][starKey]);
              if (existing) {
                existing.value = starKeySelections.length;
                existing.color = "#2ABF5B";
              } else
                temp.push({
                  text: starsList[starColumn][starKey],
                  value: starKeySelections.length,
                  color: "#2ABF5B"
                });
            }

            if (starKeySelections?.length > 0 && starType === "b") {
              const existing = temp.find((item) => item.text === starsList[starColumn][starKey]);
              if (existing) {
                existing.value2 = starKeySelections.length;
                existing.color2 = "#67AEF4";
              } else
                temp.push({
                  text: starsList[starColumn][starKey],
                  value2: starKeySelections.length,
                  color2: "#67AEF4"
                });
            }
          });
        });
      });

      setCommunicationStats(temp);
    } catch (error) {
      console.error("Error in TeamCommunicationPreferences", error);
    }
  }, [isPdf, starSelections]);

  return (
    <div
      id="communicationPreferences"
      className={
        isPdf ? "h-screen px-4 flex flex-col justify-start items-center overflow-hidden" : "mb-12"
      }>
      <Accordion
        title={isPdf ? null : teamCommunicationPreferences?.title}
        icon={isPdf ? null : teamCommunicationPreferences?.icon}
        content={content()}
        isPdf={isPdf}
      />
    </div>
  );
};

export default TeamCommunicationPreferences;
