import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { WorkshopContext } from "../../contexts/workshop.context";

import { Button, VerticalSpace } from "../../components/workshop";

import logo from "../../assets/images/logo.svg";
import boosterLogin from "../../assets/images/booster/booster-login.png";

const ThankYou = () => {
  const { t } = useTranslation();

  const { socket, user: { id, role } = {} } = useContext(WorkshopContext);
  return (
    <div className="flex flex-col gap-4">
      <img src={logo} className="w-20 h-20 mx-auto" />
      <h1
        dangerouslySetInnerHTML={{
          __html: t("thankYou.title")
        }}
        className="text-3xl text-gray-250 text-center mb-5 font-bold font-righteous"
      />
      <div className="relative">
        <VerticalSpace />
        <p
          dangerouslySetInnerHTML={{
            __html: t("thankYou.caption")
          }}
          className="text-lg text-gray-250 text-center"
        />

        <Button
          text={t("bookNow")}
          className={"max-w-lg mx-auto my-10"}
          onClick={() => (window.location.href = "http://www.mission-team.com")}
        />
        <img src={boosterLogin} className="w-64 h-64 mx-auto md:absolute top-0" />
        <p
          dangerouslySetInnerHTML={{
            __html: t("thankYou.helper")
          }}
          className="text-center text-gray-250 text-lg md:mt-24 mt-10"
        />
      </div>
    </div>
  );
};

export default ThankYou;
