import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

import { WorkshopContext } from "../../contexts/workshop.context";

import { VerticalSpace, Input, Button } from "../../components/workshop";

import logo from "../../assets/images/logo.svg";
import loginIcon from "../../assets/images/booster/booster-login.png";

const Login = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const loginTranslations = t("login", {
    returnObjects: true
  });

  const [userData, setUserData] = useState({ name: "" });
  const [loggingIn, setLoggingIn] = useState(false);
  const [error, setError] = useState("");

  const { socket, user: { id, name } = {}, setUser } = useContext(WorkshopContext);

  const loginUser = (e) => {
    e.preventDefault();

    setError("");

    console.log(userData);

    if (userData.name) {
      socket.emit("login", userData);
      setLoggingIn(true);
    }
  };

  socket.on("loginError", (error) => {
    setError(error);
    setLoggingIn(false);
  });

  socket.on("loginSuccess", (savedUserData) => {
    console.log(savedUserData);
    setUser({ ...savedUserData });
    setLoggingIn(false);
    try {
      Cookies.set("userId", savedUserData?.id, { expires: 0.125 });
    } catch (error) {
      console.log("Cookies unavailable");
    }
    navigate("/booster/wait");
  });

  useEffect(() => {
    if (id) {
      toast.info(`You are logged in as ${name}. Redirecting to wait screen.`);
      navigate("/booster/wait");
    } else {
      navigate("/booster");
    }
  }, [id]);

  return (
    <div className="flex flex-col gap-4 justify-center items-center relative">
      <img src={logo} alt="logo" className="w-24 mb-6" />
      <h1
        dangerouslySetInnerHTML={{ __html: loginTranslations.title }}
        className="text-3xl font-bold text-center font-righteous text-gray-250"
      />
      <p
        dangerouslySetInnerHTML={{ __html: loginTranslations.caption }}
        className="text-xl text-gray-250 text-center font-bold"
      />
      <p
        dangerouslySetInnerHTML={{ __html: loginTranslations.helper }}
        className="text-xl text-gray-100 text-center"
      />
      <VerticalSpace />
      <form onSubmit={loginUser} className="w-full sm:max-w-md mx-auto flex flex-col gap-3">
        <Input
          name="participantName"
          placeholder={loginTranslations?.inputPlaceholder}
          onChange={(value) => setUserData({ ...userData, name: value })}
          required={true}
          errorMessage={loginTranslations?.inputError}
        />

        {error && (
          <p
            dangerouslySetInnerHTML={{ __html: error }}
            className="text-red-500 text-lg text-center py-3"
          />
        )}

        <Button text={t("joinSession")} htmlType="submit" disabled={id || loggingIn} />
        <img
          src={loginIcon}
          alt="login"
          className="w-64 left-0 top-1/2 bottom-auto hidden md:block absolute"
        />
      </form>
      <div className="w-full flex items-center justify-center">
        <img src={loginIcon} alt="login" className="w-64 md:hidden block" />
      </div>
    </div>
  );
};

export default Login;
