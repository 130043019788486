import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, DownloadPdf } from ".";
import { BulbIcon } from "../../assets/icons";
import { FaCheck, FaCopy } from "react-icons/fa";

const InsightsHeroSection = ({
  teamName,
  id,
  platform = "mt",
  workshopDate,
  socket,
  demoWorkshop
}) => {
  const { t } = useTranslation();

  const translationPrefix = demoWorkshop ? "mtDemo." : "";
  const heroTranslations =
    platform === "mt"
      ? t(`${translationPrefix}hero`, { returnObjects: true })
      : t("mft.hero", { returnObjects: true });

  const { initialTitle, reflections, overviewImage, downloadSection } = heroTranslations || {};

  const [copied, setCopied] = useState(false);

  const content = () => {
    const { description = "", tips = [] } = reflections?.content || {};
    return (
      <div id="reflections" className="block w-full">
        <p className="mb-4 text-gray-250" dangerouslySetInnerHTML={{ __html: description }} />

        <div
          className={
            "rounded-lg border-2 border-gray-250/40 bg-[#FFF] flex items-center gap-3 p-4"
          }>
          <span className="w-12 h-12">
            <BulbIcon />
          </span>
          <ul className="list-disc pl-6">
            {tips.map((tip, index) => (
              <li
                key={index}
                className="text-[#575756]"
                dangerouslySetInnerHTML={{ __html: tip }}
              />
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const downloadSectionContent = () => {
    const { description = "", copyText = "", downloadText = "" } = downloadSection?.content || {};
    return (
      <div id="deepInsights" className="block w-full">
        <p className="mb-4 text-gray-250" dangerouslySetInnerHTML={{ __html: description }} />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 items-center justify-center">
          <div className="w-full flex gap-1 items-center justify-between h-12">
            <div
              className="border-2 border-gray-250/30 rounded-lg p-2 bg-white-100 text-gray-250 text-sm font-medium w-full h-full flex items-center"
              dangerouslySetInnerHTML={{ __html: copyText }}
            />
            <div
              className="bg-orange-100 rounded-md p-2 text-white-100 text-base font-medium w-[14%] sm:w-[10%] h-full cursor-pointer"
              onClick={() => {
                try {
                  navigator.clipboard.writeText(`${window.location.origin}/insights/${id}`);
                  setCopied(true);
                } catch (error) {
                  console.log("Error while copying", error);
                }
              }}>
              {!copied && <FaCopy />}
              {copied && <FaCheck />}
            </div>
          </div>
          <div className="w-full flex gap-1 items-center justify-between h-12">
            <div
              className="border-2 border-gray-250/30 rounded-lg p-2 bg-white-100 text-gray-250 text-sm font-medium w-full h-full flex items-center"
              dangerouslySetInnerHTML={{ __html: downloadText }}
            />
            <div className="bg-orange-100 rounded-md p-2 text-white-100 text-base font-medium w-[14%] sm:w-[10%] h-full cursor-pointer">
              <DownloadPdf
                workshopId={id}
                downloadPDFTitle={`${teamName}-${workshopDate}`}
                socket={socket}
                buttonType="icon"
                className="max-w-full w-full"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id="hero" className={"mb-12"}>
      <h2 className="text-gray-250 text-3xl font-extrabold text-center">
        <span className="uppercase">{teamName}</span>
        <span
          className="ml-2 inline-block text-3xl font-normal font-gochi text-orange-100"
          dangerouslySetInnerHTML={{ __html: initialTitle }}
        />
      </h2>

      <>
        <img src={overviewImage} className="w-full max-w-2xl block my-6 mx-auto" alt="hero" />
        <div className="my-12">
          <Accordion
            title={reflections?.title}
            icon={reflections?.icon}
            content={content()}
            isStatic={true}
            defaultOpen={true}
          />
        </div>
        {!demoWorkshop && (
          <div className="my-12">
            <Accordion
              title={downloadSection?.title}
              icon={downloadSection?.icon}
              content={downloadSectionContent()}
              isStatic={true}
              defaultOpen={true}
            />
          </div>
        )}
      </>
    </div>
  );
};

export default InsightsHeroSection;
