import React, { useEffect, useRef } from "react";

let WordCloud;

const colors = ["#3E3E3E", "#666666", "#E59536", "#BE6A03", "#EEB52B", "#F28907"];

const getRandomColor = () => {
  return colors[Math.floor(Math.random() * colors.length)];
};

export default function WordCloudRenderer({
  id,
  type = "canvas",
  words = [],
  width = 1500,
  height = 1500,
  dynamicHeightFactor = 1,
  fontFamily = "Arial, sans-serif",
  backgroundColor = "#fff",
  border = "",
  fontWeight = "bold",
  gridSize = 20,
  weightFactorFormula = (size, totalWords) => size * (totalWords <= 10 ? 80 : 40 + width / height),
  colorFunction = getRandomColor,
  shrinkToFit = true,
  drawOutOfBound = false,
  wordClickCallback = () => {},
  customClassesCallback = () => {},
  isPdf = false
}) {
  const canvasRef = useRef(null);

  const renderWordCloud = (wordsList) => {
    if (wordsList.length === 0 || !canvasRef.current) return;

    const canvas = canvasRef.current;
    canvas.setAttribute("width", width);
    const canvasPixelWidth = canvas.clientWidth;
    const canvasPixelHeight = canvas.clientHeight;
    const ratio = canvasPixelHeight / canvasPixelWidth;

    canvas.setAttribute("height", width * (ratio || 1) * dynamicHeightFactor);

    WordCloud(canvas, {
      list: wordsList,
      fontFamily,
      backgroundColor,
      fontWeight,
      gridSize,
      shrinkToFit,
      drawOutOfBound,
      clearCanvas: true,
      rotateRatio: 0.5,
      rotationSteps: 2,
      weightFactor: function (size) {
        return weightFactorFormula(size, wordsList.length, width, height);
      },
      color: function (word, weight) {
        return colorFunction(word, weight);
      },
      click: function (item) {
        wordClickCallback(item[0]);
      },
      classes: function (word) {
        return customClassesCallback(word);
      }
    });
  };

  useEffect(() => {
    if (!canvasRef?.current || !words || !words?.length || typeof window === "undefined") return;

    try {
      const canvasWidth = canvasRef.current.clientWidth;
      canvasRef.current.style.height = isPdf ? "60vh" : `${canvasWidth * dynamicHeightFactor}px`;
    } catch (error) {
      console.log("unable to set height");
    }

    (async () => {
      if (!WordCloud) WordCloud = (await import("wordcloud")).default;
      renderWordCloud(words);
    })();

    return () => {
      WordCloud?.stop && WordCloud.stop(canvasRef.current);
    };
  }, [id, words, WordCloud]);

  if (!words?.length) return null;

  return (
    <div
      id={id}
      className="px-2 py-4 border-2 border-gray-250/40 rounded-lg overflow-hidden"
      style={{
        background: backgroundColor,
        width: type === "html" && "100%",
        border: border && border
      }}>
      {type === "canvas" && (
        <canvas ref={canvasRef} style={{ display: "block", width: "100%", height: "70vh" }} />
      )}
      {type === "html" && (
        <div
          ref={canvasRef}
          style={{ display: "block", width: "100%", height: "70vh", fontFamily, fontWeight }}
        />
      )}
    </div>
  );
}
