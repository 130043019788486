import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { WorkshopContext } from "../../contexts/workshop.context";

import { ParticipantsList, ScreenBottom, Button } from "../../components/workshop";

import { defaultRoles } from "../../util/workshop.util";

const Wait = () => {
  const { t } = useTranslation();

  const { socket, user: { id } = {}, workshopUsers } = useContext(WorkshopContext);

  return (
    <div className="flex flex-col gap-4">
      <p
        dangerouslySetInnerHTML={{
          __html: t("wait.title")
        }}
        className="text-xl text-gray-250 text-center mb-5 font-bold"
      />

      <div className="bg-white-100 border border-gray-250 p-4 rounded-2xl max-w-xl mx-auto w-full">
        <ParticipantsList
          participants={workshopUsers.map((user) => ({
            ...user,
            role: defaultRoles.participant
          }))}
          verticalListOnly={true}
          moderator={true}
          userOwnId={id}
        />
      </div>

      <ScreenBottom>
        <Button text={t("joinSession")} onClick={() => socket.emit("startWorkshop")} />
      </ScreenBottom>
    </div>
  );
};

export default Wait;
