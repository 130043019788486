import { useEffect, useState } from "react";
import Popup from "./popup";

const ImageCloudV2 = ({ images, pdf = false, customClickCallback }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [clickedImageUrl, setClickedImageUrl] = useState(null);
  const [renderingCloud, setRenderingCloud] = useState(true);

  useEffect(() => {
    const collage = document.getElementById("collage");
    if (!collage) return;
    const { width: collageWidth, height: collageHeight } = collage.getBoundingClientRect();

    let startingSize = 220;

    const sortItemsBySize = () => {
      const allCollageItems = document.querySelectorAll(".collage_item");
      if (allCollageItems.length === 0) return;
      const allItemsDescending = [];
      allCollageItems.forEach((item) => {
        const { clientWidth } = item;
        allItemsDescending.push({
          width: clientWidth,
          node: item
        });
      });
      allItemsDescending.sort((a, b) => parseFloat(b.width) - parseFloat(a.width));
      return allItemsDescending;
    };

    const findTopItem = () => {
      // Find top most available item
      let topItemNode;
      let topItemValue = 9999999;
      document.querySelectorAll("[data-top]").forEach((collage_item_test) => {
        if (parseFloat(collage_item_test.dataset.top) < topItemValue) {
          topItemNode = collage_item_test;
          topItemValue = parseFloat(collage_item_test.dataset.top);
        }
      });
      return topItemNode;
    };
    const findLeftItem = () => {
      // Find left most available item
      let leftItemNode;
      let leftItemValue = 9999999;
      document.querySelectorAll("[data-left]").forEach((collage_item_test) => {
        if (parseFloat(collage_item_test.dataset.left) < leftItemValue) {
          leftItemNode = collage_item_test;
          leftItemValue = parseFloat(collage_item_test.dataset.left);
        }
      });
      return leftItemNode;
    };
    const findRightItem = () => {
      // Find right most available item
      let rightItemNode;
      let rightItemValue = 0;
      document.querySelectorAll("[data-left]").forEach((collage_item_test) => {
        if (parseFloat(collage_item_test.dataset.left) > rightItemValue) {
          rightItemNode = collage_item_test;
          rightItemValue = parseFloat(collage_item_test.dataset.left);
        }
      });
      return rightItemNode;
    };

    let canvasWidth = 0;
    let canvasHeight = 0;
    let tempWidth = 0;
    let tempHeight = 0;

    let buildOnTopLeftCorner = false;
    let buildOnTop = true;
    let buildOnTopRight = false;
    let buildOnTopRightCorner = false;
    let buildOnBottomRightCorner = false;
    let buildOnBottomLeftCorner = false;
    let buildOnRight = false;
    let buildOnBottom = false;
    let buildOnleft = false;

    let prevRightItem;

    const nullifyDirection = () => {
      buildOnTopLeftCorner = false;
      buildOnTop = false;
      buildOnTopRight = false;
      buildOnTopRightCorner = false;
      buildOnBottomRightCorner = false;
      buildOnBottomLeftCorner = false;
      buildOnRight = false;
      buildOnBottom = false;
      buildOnleft = false;
    };

    const findNextItemPosition = (item, first = false) => {
      const { width: nodeSize, node: collage_item } = item;
      if (first) {
        collage_item.style.top = `${collageHeight / 2 - nodeSize / 2}px`;
        collage_item.dataset.top = `${collageHeight / 2 - nodeSize / 2}`;
        collage_item.style.left = `${collageWidth / 2 - nodeSize / 2}px`;
        collage_item.dataset.left = `${collageWidth / 2 - nodeSize / 2}`;
        canvasWidth = nodeSize;
        canvasHeight = nodeSize;
        return;
      }

      if (buildOnTop) {
        // console.log("Build on top", collage_item);
        const topItemNode = findTopItem();
        const leftItemNode = findLeftItem();

        // Now lets calculate its position
        const newTopPosition = parseFloat(topItemNode.dataset.top) - nodeSize;
        const newLeftPosition = parseFloat(leftItemNode.dataset.left);

        collage_item.style.top = `${newTopPosition}px`;
        collage_item.dataset.top = `${newTopPosition}`;
        collage_item.style.left = `${newLeftPosition}px`;
        collage_item.dataset.left = `${newLeftPosition}`;

        tempWidth = nodeSize;
        canvasHeight = canvasHeight + nodeSize;
        prevRightItem = collage_item;

        // Find next direction
        nullifyDirection();
        buildOnTopRight = true;
        //
      } else if (buildOnTopRight) {
        // console.log("Build Top Right", collage_item);

        // Now lets calculate its position
        const newTopPosition = parseFloat(prevRightItem.dataset.top);
        const newLeftPosition = parseFloat(prevRightItem.dataset.left) + prevRightItem.clientWidth;

        collage_item.style.top = `${newTopPosition}px`;
        collage_item.dataset.top = `${newTopPosition}`;
        collage_item.style.left = `${newLeftPosition}px`;
        collage_item.dataset.left = `${newLeftPosition}`;

        prevRightItem = collage_item;
        tempWidth = tempWidth + nodeSize;

        // Find next direction
        nullifyDirection();
        if (tempWidth < canvasWidth) buildOnTopRight = true;
        else {
          buildOnTopRightCorner = true;
          canvasWidth = tempWidth;
          tempWidth = 0;
          tempHeight = 0;
        }
        //
      } else if (buildOnTopRightCorner) {
        // console.log("Build Top Right Corner", collage_item);
        const topItemNode = findTopItem();
        const rightItemNode = findRightItem();

        // Now lets calculate its position
        const newTopPosition = parseFloat(topItemNode.dataset.top);
        const newLeftPosition = parseFloat(rightItemNode.dataset.left) + rightItemNode.clientWidth;

        collage_item.style.top = `${newTopPosition}px`;
        collage_item.dataset.top = `${newTopPosition}`;
        collage_item.style.left = `${newLeftPosition}px`;
        collage_item.dataset.left = `${newLeftPosition}`;

        tempWidth = tempWidth + nodeSize;
        prevRightItem = collage_item;

        // Find next direction
        nullifyDirection();
        // if (tempHeight <= canvasHeight)
        buildOnRight = true;
        // else {
        //   buildOnBottomRightCorner = true;
        canvasWidth = canvasWidth + nodeSize;
        tempWidth = 0;
        tempHeight = nodeSize;
        // }
        //
      } else if (buildOnRight) {
        // console.log("Build on Right", collage_item);

        // Now lets calculate its position
        const newTopPosition = parseFloat(prevRightItem.dataset.top) + prevRightItem.clientHeight;
        const newLeftPosition = parseFloat(prevRightItem.dataset.left);

        collage_item.style.top = `${newTopPosition}px`;
        collage_item.dataset.top = `${newTopPosition}`;
        collage_item.style.left = `${newLeftPosition}px`;
        collage_item.dataset.left = `${newLeftPosition}`;

        tempHeight = tempHeight + nodeSize;
        prevRightItem = collage_item;

        // Find next direction
        nullifyDirection();
        if (tempHeight < canvasHeight) buildOnRight = true;
        else {
          buildOnBottomRightCorner = true;
          canvasHeight = tempHeight;
          tempWidth = 0;
          tempHeight = 0;
        }
        // Update
        //
      } else if (buildOnBottomRightCorner) {
        // console.log("Build on Right Bottom corner", collage_item);

        // Now lets calculate its position
        const newTopPosition = parseFloat(prevRightItem.dataset.top) + prevRightItem.clientHeight;
        const newLeftPosition = parseFloat(prevRightItem.dataset.left);

        collage_item.style.top = `${newTopPosition}px`;
        collage_item.dataset.top = `${newTopPosition}`;
        collage_item.style.left = `${newLeftPosition}px`;
        collage_item.dataset.left = `${newLeftPosition}`;

        tempHeight = tempHeight + nodeSize;
        prevRightItem = collage_item;

        // Find next direction
        nullifyDirection();

        buildOnBottom = true;
        canvasHeight = canvasHeight + nodeSize;
        // canvasWidth = canvasWidth + nodeSize;
        tempWidth = nodeSize;
        tempHeight = 0;

        // Update
        //
      } else if (buildOnBottom) {
        // console.log("Build on bottom", collage_item);

        // Now lets calculate its position
        const newTopPosition = parseFloat(prevRightItem.dataset.top);
        const newLeftPosition = parseFloat(prevRightItem.dataset.left) - prevRightItem.clientWidth;

        collage_item.style.top = `${newTopPosition}px`;
        collage_item.dataset.top = `${newTopPosition}`;
        collage_item.style.left = `${newLeftPosition}px`;
        collage_item.dataset.left = `${newLeftPosition}`;

        tempWidth = tempWidth + nodeSize;
        prevRightItem = collage_item;

        // Find next direction
        nullifyDirection();
        if (tempWidth < canvasWidth) buildOnBottom = true;
        else {
          buildOnBottomLeftCorner = true;
          // canvasWidth = tempWidth;
          tempWidth = 0;
          tempHeight = 0;
        }
        // Update
        //
      } else if (buildOnBottomLeftCorner) {
        // console.log("Build on bottom left corner", collage_item);

        // Now lets calculate its position
        const newTopPosition = parseFloat(prevRightItem.dataset.top);
        const newLeftPosition = parseFloat(prevRightItem.dataset.left) - prevRightItem.clientWidth;

        collage_item.style.top = `${newTopPosition}px`;
        collage_item.dataset.top = `${newTopPosition}`;
        collage_item.style.left = `${newLeftPosition}px`;
        collage_item.dataset.left = `${newLeftPosition}`;

        tempWidth = tempWidth + nodeSize;
        prevRightItem = collage_item;

        // Find next direction
        nullifyDirection();
        buildOnleft = true;
        // canvasHeight = canvasHeight + nodeSize;
        canvasWidth = canvasWidth + nodeSize;
        tempWidth = 0;
        tempHeight = nodeSize;

        // Update
        //
      } else if (buildOnleft) {
        // console.log("Build on left", collage_item);

        // Now lets calculate its position
        const newTopPosition = parseFloat(prevRightItem.dataset.top) - prevRightItem.clientHeight;
        const newLeftPosition = parseFloat(prevRightItem.dataset.left);

        collage_item.style.top = `${newTopPosition}px`;
        collage_item.dataset.top = `${newTopPosition}`;
        collage_item.style.left = `${newLeftPosition}px`;
        collage_item.dataset.left = `${newLeftPosition}`;

        tempHeight = tempHeight + nodeSize;
        prevRightItem = collage_item;

        // Find next direction
        nullifyDirection();
        if (tempHeight < canvasHeight) buildOnleft = true;
        else {
          buildOnTop = true;
          canvasHeight = tempHeight;
          tempWidth = 0;
          tempHeight = 0;
        }

        // Update
        //
      }
    };

    let collisionLimitReached = false;

    const checkOutOfBounds = () => {
      const collageContainer = document.getElementById("collage");
      const collageRect = collageContainer.getBoundingClientRect();
      const allCollageItems = collageContainer.querySelectorAll(".collage_item");

      // Define a tolerance margin (in pixels)
      const tolerance = 10;

      let shrink = false;
      for (let i = 0; i < allCollageItems.length; i++) {
        const itemRect = allCollageItems[i].getBoundingClientRect();

        if (
          itemRect.top < collageRect.top - tolerance ||
          itemRect.left < collageRect.left - tolerance ||
          itemRect.right > collageRect.right + tolerance ||
          itemRect.bottom > collageRect.bottom + tolerance
        ) {
          shrink = true;
          break;
        }
      }

      return shrink;
    };

    const centerTheImages = () => {
      try {
        const leftItem = findLeftItem();
        const topItem = findTopItem();

        const moveLeftAmount = Number(leftItem.dataset.left) / 2;
        const moveBottomAmount = Number(topItem.dataset.top) / 2;

        document.querySelectorAll(".collage_item").forEach((item) => {
          item.style.left = `${parseFloat(item.style.left) - moveLeftAmount}px`;
          if (!pdf) item.style.top = `${parseFloat(item.style.top) - moveBottomAmount}px`;
        });
      } catch (error) {
        console.error("Unable to center images", error);
      } finally {
        setRenderingCloud(false);

        if (pdf) {
          collage.style.transform = "scale(0.7) translateY(-18%)";
        }
      }
    };

    const buildCollage = (list) => {
      // Insert first image in center first
      if (!list?.length) return;
      findNextItemPosition(list[0], true);
      for (let i = 1; i < list.length; i++) {
        findNextItemPosition(list[i]);
      }
      if (checkOutOfBounds() && !collisionLimitReached) {
        initCollage(true);
      } else {
        // Now center it
        centerTheImages();
      }
    };

    // Collage builder
    const buildCollageHTML = (collage, images, startingSize = 220) => {
      collage.innerHTML = "";

      // Based on number of images
      const sortedImages = images.sort((a, b) => b.score - a.score);

      const collageInner = document.createElement("div");
      collageInner.className = "collage_inner";

      sortedImages.forEach(({ url, score }) => {
        const collageItem = document.createElement("div");
        collageItem.className = "collage_item";
        collageItem.style.setProperty("--score", `${startingSize * score}px`);

        const collageItemInner = document.createElement("div");
        collageItemInner.className = "collage_item_inner";

        const img = document.createElement("img");
        img.src = url;
        img.alt = "Selection";

        // Add click event listener
        img.addEventListener("click", function () {
          if (customClickCallback) {
            customClickCallback(url);
            return;
          }
          setClickedImageUrl(url);
          setShowPopup(true);
        });

        collageItemInner.appendChild(img);
        collageItem.appendChild(collageItemInner);
        collageInner.appendChild(collageItem);
      });

      collage.appendChild(collageInner);
    };

    const initCollage = (shrink = false) => {
      nullifyDirection();
      buildOnTop = true;

      if (shrink && startingSize >= 30) {
        startingSize -= 10;
      }
      if (startingSize <= 20) collisionLimitReached = true;

      // Build HTMl
      buildCollageHTML(collage, images, startingSize);

      // Sort in size
      const sorted = sortItemsBySize();

      // Draw them on canvas now
      buildCollage(sorted);
    };

    initCollage();
  }, [images]);

  return (
    <>
      <div id="collage"></div>
      {!pdf && (
        <Popup
          open={showPopup}
          setOpen={() => {
            setShowPopup(false);
            setClickedImageUrl(null);
          }}
          autoHeight={true}>
          <img src={clickedImageUrl} alt="" className="w-full" />
        </Popup>
      )}

      {renderingCloud && <span id="imageCloudRendering" />}
    </>
  );
};

export default ImageCloudV2;
