import React, { useState, useEffect } from "react";

import { htmlToText } from "../../util/translations.util";

const Input = ({
  name,
  options = [], // [{ value: "value", label: "label" }]
  placeholder,
  defultValue,
  onChange,
  required,
  className = "",
  errorMessage = "This field is required"
}) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (defultValue !== undefined) setValue(defultValue);
  }, [defultValue]);

  return (
    <label htmlFor={name} className="block w-full">
      <select
        name={name}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          if (onchange) onChange(e.target.value);
        }}
        required={required}
        className={`block w-full rounded-lg outline-none bg-white border-[0.5px] border-gray-250 text-black-100 py-4 px-6 text-lg placeholder:text-gray-300 ${className}`}
        onInvalid={(e) => {
          e.target.setCustomValidity(htmlToText(errorMessage));
        }}>
        <option value="" disabled>
          {htmlToText(placeholder)}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </label>
  );
};

export default Input;
