import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { WorkshopContext } from "../contexts/workshop.context";
import { Button } from "../components/workshop";

import logo from "../assets/images/logo.svg";
import lynnImage from "../assets/images/lynn.svg";
import { defaultRoles } from "../util/workshop.util";

const PreSummary = ({ demoWorkshop = false }) => {
  const { t } = useTranslation();

  const { user: { role } = {}, workshopDetails: { _id, teamName } = {} } =
    useContext(WorkshopContext);

  // If someone is landing here without being part of workshop
  useEffect(() => {
    if (_id && teamName && !role) {
      window.location.href = demoWorkshop ? "/insights/demo" : `/insights/${_id}`;
    }
  }, [_id, teamName]);

  return (
    <section id="presummary" className="relative max-w-2xl mx-auto flex flex-col gap-4">
      <img
        src={lynnImage}
        alt="lynn"
        className="w-72 mx-auto absolute -left-1/3 -top-14 rotate-45 z-10"
      />

      <div className="relative z-20">
        <img src={logo} alt="logo" className="w-28 mx-auto" />
        <h3
          className="font-medium text-orange-100 text-center font-righteous text-3xl"
          dangerouslySetInnerHTML={{ __html: teamName }}
        />

        {role === defaultRoles.participant && (
          <>
            <h1
              dangerouslySetInnerHTML={{ __html: t("preSummary.heading") }}
              className="text-5xl font-medium font-righteous text-gray-250 mt-4"
            />
            <p
              dangerouslySetInnerHTML={{ __html: t("preSummary.subheading") }}
              className="text-xl text-gray-250 font-medium"
            />
            <p
              dangerouslySetInnerHTML={{ __html: t("preSummary.description") }}
              className="text-xl text-gray-100 mb-4"
            />
          </>
        )}

        {role === defaultRoles.moderatorAndParticipant && (
          <>
            <h1
              dangerouslySetInnerHTML={{
                __html: t(demoWorkshop ? "preSummary.heading" : "preSummary.heading1")
              }}
              className="text-5xl font-medium font-righteous text-gray-250 mt-4"
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t(demoWorkshop ? "preSummary.subheading" : "preSummary.subheading1")
              }}
              className="text-xl text-gray-250 font-medium"
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t(demoWorkshop ? "preSummary.description" : "preSummary.description1")
              }}
              className="text-xl text-gray-100 mb-4"
            />
          </>
        )}

        {role === defaultRoles.moderator && (
          <>
            <h1
              dangerouslySetInnerHTML={{ __html: t("preSummary.heading2") }}
              className="text-5xl font-medium font-righteous text-gray-250 mt-4"
            />
            <p
              dangerouslySetInnerHTML={{ __html: t("preSummary.subheading2") }}
              className="text-xl text-gray-250 font-medium"
            />
            <p
              dangerouslySetInnerHTML={{ __html: t("preSummary.description2") }}
              className="text-xl text-gray-100 mb-4"
            />
          </>
        )}

        <Button
          text={t("preSummary.button")}
          onClick={() => {
            window.location.href = demoWorkshop ? "/insights/demo" : `/insights/${_id}`;
          }}
        />
      </div>
    </section>
  );
};

export default PreSummary;
