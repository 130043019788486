export const defaultRoles = {
  participant: "participant",
  moderator: "moderator",
  moderatorAndParticipant: "moderatorAndParticipant",
  demo: "demo"
};

export const defaultPlatforms = {
  mt: "mt",
  mft: "mft",
  mtOnline: "mtOnline",
  mtDemo: "mtDemo"
};

export const ALLOWED_SKILLS = 2;

export const VideoStatusDefaults = {
  paused: "paused",
  playing: "playing"
};

export const iconsMappingList = [
  ["magnifier", "head"],
  ["magnifier", "person"],
  ["radar", "head"],
  ["radar", "person"]
];

export const VideoById = {
  mt: {
    1: {
      en: "907650816",
      de: "907608533",
      fr: "912229209",
      it: "927420569"
    },
    2: {
      en: "907650844",
      de: "907608596",
      fr: "912229334",
      it: "927420625"
    },
    3: {
      en: "907650912",
      de: "907611331",
      fr: "912228197",
      it: "927420734"
    },
    4: {
      en: "907650944",
      de: "907611379",
      fr: "912228261",
      it: "927420790"
    },
    5: {
      en: "907651111",
      de: "907611283",
      fr: "912228691",
      it: "927420974"
    },
    6: {
      en: "907651153",
      de: "907608303",
      fr: "912228805",
      it: "927421022"
    },
    7: {
      en: "907651180",
      de: "907608346",
      fr: "912228873",
      it: "927421053"
    },
    8: {
      en: "907651215",
      de: "907608393",
      fr: "912228972",
      it: "927421094"
    },
    9: {
      en: "907651263",
      de: "907608430",
      fr: "912229050",
      it: "927421144"
    },
    10: {
      en: "907651292",
      de: "907608495",
      fr: "912229139",
      it: "927421182"
    }
  },
  mft: {
    1: {
      en: "907657305",
      de: "907669115",
      fr: "1000379041",
      it: "927420662"
    },
    2: {
      en: "907657349",
      de: "907669178",
      fr: "1000378983",
      it: "927420742"
    },
    3: {
      en: "907657401",
      de: "907669218",
      fr: "1000378934",
      it: "927420795"
    },
    4: {
      en: "907657448",
      de: "907669010",
      fr: "1000378846",
      it: "927420843"
    },
    5: {
      en: "907657518",
      de: "907669059",
      fr: "913656084",
      it: "927420889"
    },
    6: {
      en: "907657635",
      de: "907669099",
      fr: "913657818",
      it: "927420944"
    }
  },
  mtOnline: {
    1: {
      en: "907650816",
      de: "907608533",
      fr: "912229209",
      it: "927420569"
    },
    2: {
      en: "907650844",
      de: "907608596",
      fr: "912229334",
      it: "927420625"
    },
    3: {
      en: "907650912",
      de: "907611331",
      fr: "912228197",
      it: "927420734"
    },
    4: {
      en: "907650944",
      de: "907611379",
      fr: "912228261",
      it: "927420790"
    },
    5: {
      en: "907651111",
      de: "907611283",
      fr: "912228691",
      it: "927420974"
    },
    6: {
      en: "907651153",
      de: "907608303",
      fr: "912228805",
      it: "927421022"
    },
    7: {
      en: "907651180",
      de: "907608346",
      fr: "912228873",
      it: "927421053"
    },
    8: {
      en: "907651215",
      de: "907608393",
      fr: "912228972",
      it: "927421094"
    },
    9: {
      en: "907651263",
      de: "907608430",
      fr: "912229050",
      it: "927421144"
    },
    10: {
      en: "907651292",
      de: "907608495",
      fr: "912229139",
      it: "927421182"
    }
  },
  mtDemo: {
    1: {
      en: "907651153",
      de: "907608303",
      fr: "912228805",
      it: "927421022"
    },
    2: {
      en: "907651215",
      de: "907608393",
      fr: "912228972",
      it: "927421094"
    }
  }
};

export const NonPrivateRoutes = [
  "/",
  "/login",
  "/booster",
  "/online",
  "/wait",
  "/booster/wait",
  "/online/wait",
  "/end"
];
