import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "./button";
import Popup from "./popup";

const CookieBanner = () => {
  const [cookieConsentPopup, setCookieConsentPopup] = useState(false);
  const cookieConsent = Cookies.get("consent");
  const { t } = useTranslation();

  useEffect(() => {
    if (!cookieConsent) {
      setCookieConsentPopup(true);
    }
  }, [cookieConsent]);

  return (
    <div>
      <Popup open={cookieConsentPopup} hideOnActionBtnOnly={true} autoHeight={true}>
        <p className="mb-6">{t("cookieBanner.text")}</p>
        <Button
          text={t("cookieBanner.acceptBtn")}
          onClick={() => {
            Cookies.set("consent", true, { expires: 365 });
            setCookieConsentPopup(false);
          }}
        />
      </Popup>
    </div>
  );
};

export default CookieBanner;
