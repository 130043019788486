import axios from "axios";

const WORLDSKILLS_API_URL = process.env.REACT_APP_WORLDSKILLS_API_URL;
const PSYFIERS_API_URL = process.env.REACT_APP_PSYFIERS_API_URL;
const MISSION_TEAM_API_URL = process.env.REACT_APP_MISSION_TEAM_API_URL;

export const fetchTranslations = (id) => {
  return axios.get(`${WORLDSKILLS_API_URL}/public/translations/${id}`);
};

export const getPortalDetails = (m, p) => {
  return axios.get(`${PSYFIERS_API_URL}/public/inperson-workshop-details`, {
    params: { m, p }
  });
};

export const getBoosterWorkshopDetails = (id) =>
  axios.get(`${MISSION_TEAM_API_URL}/public/booster/${id}`);

export const getWorkshopInsightsData = (id) =>
  axios.get(`${PSYFIERS_API_URL}/public/workshop-insights/${id}`);
