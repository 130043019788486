import React from "react";
import { useTranslation } from "react-i18next";
import {
  MagnifierIcon,
  HeadIcon,
  RadarIcon,
  PersonIcon,
  StarIcon,
  HexagonIcon
} from "../../assets/icons";
import { getInitials } from "../../util/general.util";

const CommunicationMap = ({ starSelections, isPdf }) => {
  const { t } = useTranslation();
  const starsList = t("resources.starsList", { returnObjects: true });

  const mapData = [
    {
      icons: [MagnifierIcon, HeadIcon],
      text: "Magnifier & Head"
    },
    {
      icons: [MagnifierIcon, PersonIcon],
      text: "Magnifier & Belly"
    },
    {
      icons: [RadarIcon, HeadIcon],
      text: "Radar & Head"
    },
    {
      icons: [RadarIcon, PersonIcon],
      text: "Radar & Belly"
    }
  ];

  return (
    <div
      className={
        isPdf
          ? "grid grid-cols-4 gap-4 w-full mx-auto"
          : "lg:grid lg:grid-cols-4 gap-4 w-full my-8 mx-auto lg:my-12"
      }>
      {mapData.map((map, index) => (
        <MapItem
          key={index}
          {...map}
          index={index}
          starSelections={starSelections}
          starsList={starsList[index]}
          isPdf={isPdf}
        />
      ))}
    </div>
  );
};

const MapItem = ({ icons, text, index, starSelections, starsList, isPdf }) => {
  const mapStarSelectionsA = starSelections?.a?.[index] || {};
  const mapStarSelectionsB = starSelections?.b?.[index] || {};

  return (
    <div className="w-full relative rounded-lg bg-white-100 mb-4 lg:mb-0 border border-gray-250/40">
      {!isPdf ? (
        <div className={`h-96 lg:h-60 p-2 relative`}>
          <img
            src={require(`../../assets/images/maps/${index}.png`)}
            className="w-full h-full object-cover rounded-md"
            alt="map"
          />
          <div className="absolute inset-0 flex justify-center items-center space-x-4">
            {icons.map((Icon, i) => (
              <div
                key={i}
                className="w-12 h-12 bg-gray-250 rounded-full flex items-center justify-center">
                <Icon bg={"#454444"} iconColor={"#fff"} />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={`p-2 flex items-center justify-between`}>
          <p className="text-lg font-gochi text-orange-100">{text}</p>
          <div className="flex justify-center items-center space-x-2">
            {icons.map((Icon, i) => (
              <div
                key={i}
                className="w-6 h-6 bg-orange-100 rounded-full flex items-center justify-center">
                <Icon iconColor={"#fff"} />
              </div>
            ))}
          </div>
        </div>
      )}

      <div className={`${isPdf ? "px-4 py-2" : "p-4"} rounded-md bg-white-100`}>
        {Object.entries(starsList).map(([optionKey, optionText], i) => (
          <div key={i} className="flex flex-col gap-1 mb-2">
            <p className="text-xs" dangerouslySetInnerHTML={{ __html: optionText }} />
            <div className="flex flex-wrap items-start">
              {mapStarSelectionsA[optionKey]?.map((user, j) => (
                <Symbol
                  key={`a-${j}`}
                  name={user.name}
                  type="a"
                  size={isPdf ? "small" : "normal"}
                />
              ))}
              {mapStarSelectionsB[optionKey]?.map((user, j) => (
                <Symbol
                  key={`b-${j}`}
                  name={user.name}
                  type="b"
                  size={isPdf ? "small" : "normal"}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Symbol = ({ name, type, size = "normal" }) => {
  const Icon = type === "a" ? StarIcon : HexagonIcon;

  const iconSizeClasses = size === "small" ? "w-6 h-6 text-[0.5rem]" : "w-9 h-9 mr-2";
  const textSizeClasses = size === "small" ? "text-[0.5rem]" : "text-xs";

  return (
    <span className={`relative flex items-center justify-center ${iconSizeClasses}`}>
      <span className="w-full h-full absolute top-0 left-0">
        <Icon filled={true} />
      </span>
      <span className={`relative text-xs text-white-100 uppercase ${textSizeClasses}`}>
        {getInitials(name)}
      </span>
    </span>
  );
};

export default CommunicationMap;
