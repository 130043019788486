import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Hamburger from "hamburger-react";

import logo from "../../assets/images/logo.svg";
import logoMft from "../../assets/images/mftLogo.svg";

const InsightsHeader = ({ platform = "mt" }) => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);

  const getTranslationPrefix = () => {
    switch (platform) {
      case "mft":
        return "mft.header";
      case "mtDemo":
        return "mtDemo.header";
      default:
        return "header";
    }
  };

  const translationPrefix = getTranslationPrefix();
  const headerTranslations = t(translationPrefix, { returnObjects: true });
  const { maps, strengths, roles, communication, skills, successPillars } = headerTranslations;

  const getLogo = () => (platform === "mft" ? logoMft : logo);

  const scrollToSection = (id) => {
    try {
      const targetSection = document.getElementById(id);
      targetSection.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    } catch (error) {
      console.error("Unable to scroll", error);
    } finally {
      setOpen(false);
    }
  };

  const navigationMenu = () => {
    return (
      <div className="flex flex-col sm:flex-row gap-4 items-center flex-wrap">
        {maps && (
          <span className="flex gap-1 items-center">
            {maps?.icon && (
              <span className="w-5 h-5" dangerouslySetInnerHTML={{ __html: maps?.icon }} />
            )}
            <span
              className="font-normal cursor-pointer text-gray-250"
              onClick={() => scrollToSection("maps")}
              dangerouslySetInnerHTML={{ __html: maps?.title }}
            />
          </span>
        )}

        {strengths && (
          <span className="flex gap-1 items-center">
            {strengths?.icon && (
              <span className="w-5 h-5" dangerouslySetInnerHTML={{ __html: strengths?.icon }} />
            )}
            <span
              className="font-normal cursor-pointer text-gray-250"
              onClick={() => scrollToSection("strengths")}
              dangerouslySetInnerHTML={{ __html: strengths?.title }}
            />
          </span>
        )}

        {roles && (
          <span className="flex gap-1 items-center">
            {roles?.icon && (
              <span className="w-5 h-5" dangerouslySetInnerHTML={{ __html: roles?.icon }} />
            )}
            <span
              className="font-normal cursor-pointer text-gray-250"
              onClick={() => scrollToSection("roles")}
              dangerouslySetInnerHTML={{ __html: roles?.title }}
            />
          </span>
        )}

        {communication && (
          <span className="flex gap-1 items-center">
            {communication?.icon && (
              <span className="w-5 h-5" dangerouslySetInnerHTML={{ __html: communication?.icon }} />
            )}
            <span
              className="font-normal cursor-pointer text-gray-250"
              onClick={() => scrollToSection("communicationPreferences")}
              dangerouslySetInnerHTML={{ __html: communication?.title }}
            />
          </span>
        )}

        {skills && (
          <span className="flex gap-1 items-center">
            {skills?.icon && (
              <span className="w-5 h-5" dangerouslySetInnerHTML={{ __html: skills?.icon }} />
            )}
            <span
              className="font-normal cursor-pointer text-gray-250"
              onClick={() => scrollToSection("skills")}
              dangerouslySetInnerHTML={{ __html: skills?.title }}
            />
          </span>
        )}

        {successPillars && (
          <span className="flex gap-1 items-center">
            {successPillars?.icon && (
              <span
                className="w-5 h-5"
                dangerouslySetInnerHTML={{ __html: successPillars?.icon }}
              />
            )}
            <span
              className="font-normal cursor-pointer text-gray-250"
              onClick={() => scrollToSection("successPillars")}
              dangerouslySetInnerHTML={{ __html: successPillars?.title }}
            />
          </span>
        )}
      </div>
    );
  };

  return (
    <nav className="w-full mb-6 py-4 flex items-center justify-between flex-wrap gap-2">
      <img className="w-12 h-12 block" src={getLogo()} alt="logo" />
      <div className="hidden sm:block">{navigationMenu()}</div>
      <span className="block sm:hidden">
        <Hamburger toggled={isOpen} toggle={setOpen} rounded hideOutline color="#454444" />
      </span>
      <div
        className="fixed right-0 top-0 w-full h-full bg-white-100/20 backdrop-blur-md transition-all p-6 py-12 z-[99999]"
        style={{
          transform: `translateX(${isOpen ? "0" : "100%"})`
        }}>
        <div className="flex justify-end my-4">
          <Hamburger toggled={isOpen} toggle={setOpen} rounded hideOutline color="#454444" />
        </div>
        {navigationMenu()}
      </div>
    </nav>
  );
};

export default InsightsHeader;
