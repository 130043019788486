import React from "react";

const Emoji = ({
  index,
  symbol,
  className = "",
  hasHoverState = true,
  selected,
  onSelect = () => {}
}) => {
  const hoverClasses = hasHoverState
    ? "hover:scale-125 hover:drop-shadow hover:bg-[rgba(229,149,54,0.35)]"
    : "";
  return (
    <span
      className={`text-5xl cursor-pointer border-2 border-transparent p-[4px] -mt-1.5 rounded-full flex items-center justify-center ${hoverClasses} ${selected ? "scale-125 bg-[rgba(229,149,54,0.35)]" : ""} ${className} `}
      role="img"
      onClick={() => onSelect(index)}>
      <span className="font-emoji">{String.fromCodePoint(`0x${symbol}`)}</span>
    </span>
  );
};

export default Emoji;
