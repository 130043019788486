import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { WorkshopContext } from "../../contexts/workshop.context";

import {
  ScreenBottom,
  Button,
  CountdownTimer,
  ParticipantsReadyList,
  ProgressBar,
  StrengthsImagePicker
} from "../../components/workshop";

import logo from "../../assets/images/logo.svg";

const ChooseStrengths = () => {
  const { t, i18n } = useTranslation();

  const [isReady, setIsReady] = useState(false);
  const [readyUsers, setReadyUsers] = useState([]);

  const {
    socket,
    user: { id } = {},
    workshopUsers,
    themeConfig: { primary } = {}
  } = useContext(WorkshopContext);

  const readyAllParticipants = () => {
    workshopUsers.forEach((user) => {
      socket.emit("strengthReady", user.id);
    });
  };

  socket.on("strengthReadyList", (data) => {
    console.log("strengthReadyList", data);
    if (!data.includes(id)) return;

    setReadyUsers(data);
    setIsReady(true);
  });

  useEffect(() => {
    socket.emit("strengthReadyList", (data) => {
      console.log("strengthReadyList", data);
      if (!data.includes(id)) return;

      setReadyUsers(data);
      setIsReady(true);
    });
  }, []);

  return isReady ? (
    <>
      <ProgressBar defaultColor={"#fff"} progressColor={primary} total={6} progress={2} />
      <h3
        dangerouslySetInnerHTML={{
          __html: t("strengths.waitingHelper")
        }}
        className="text-xl text-gray-250 text-center font-righteous mt-5"
      />
      <div className="flex justify-center mt-5">
        <CountdownTimer
          id={"booster-strengths-main-timer"}
          totalTime={180}
          start={true}
          onComplete={readyAllParticipants}
        />
      </div>
      <div>
        <ParticipantsReadyList
          idPrefix="booster-strengths"
          readyUsers={readyUsers}
          // Not using individual timers for now
          // onComplete={(participantid) => {
          //   console.log("onComplete", participantid);
          //   socket.emit("strengthReady", participantid);
          // }}
          showTimer={false}
        />
      </div>
    </>
  ) : (
    <>
      <div className="flex flex-col gap-4">
        <ProgressBar defaultColor={"#fff"} progressColor={primary} total={6} progress={2} />

        <div>
          <div className=" flex justify-center relative mt-5">
            <img src={logo} className="w-8 h-8 absolute left-0 top-auto bottom-auto" />
            <h1
              dangerouslySetInnerHTML={{
                __html: t("strengths.title")
              }}
              className="text-3xl text-gray-250 text-center font-bold font-righteous"
            />
          </div>

          <p
            dangerouslySetInnerHTML={{
              __html: t("strengths.helper")
            }}
            className="text-lg text-gray-250 text-center font-bold"
          />

          <p
            dangerouslySetInnerHTML={{
              __html: t("strengths.caption")
            }}
            className="text-lg text-gray-250 text-center"
          />
        </div>

        <div className="flex justify-center">
          <CountdownTimer id={"booster-strengths-main-timer"} totalTime={180} start={true} />
        </div>

        <StrengthsImagePicker
          options={[...Array(15).keys()].map((_, i) => ({
            id: i,
            img: require(`../../assets/images/booster/strengths/${i18n.language}_${i + 1}.jpg`)
          }))}
        />

        <ScreenBottom>
          <Button text={t("ready")} onClick={() => socket.emit("strengthReady", id)} />
        </ScreenBottom>
      </div>
    </>
  );
};

export default ChooseStrengths;
