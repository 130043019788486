import React from "react";

import { CloseIcon } from "../../assets/icons";
import Button from "./button";

const Popup = ({
  open,
  setOpen,
  bottomPadding = true,
  autoHeight = false,
  maxWidth = "sm:max-w-md",
  hideOnActionBtnOnly = false,
  headerButton: { btnText, callback } = {},
  noSpacePopup = false,
  mobilePopupStyle = true,
  children
}) => {
  return (
    <div
      className={`h-screen sm:py-[5%] sm:overflow-y-auto fixed top-0 left-0 z-50 w-full backdrop-blur-sm items-center justify-center ${open ? "flex" : "hidden"} ${!mobilePopupStyle ? "px-2" : ""}`}>
      <div
        aria-label="close"
        title="close"
        className="absolute top-0 left-0 right-0 bottom-0 z-[60]"
        onClick={(e) => {
          e.stopPropagation();
          if (hideOnActionBtnOnly) return;
          setOpen(false);
        }}
      />
      <div
        data-testid="popup-content"
        className={`z-[70] max-h-[70%] ${autoHeight ? "h-auto max-h-max" : "h-full sm:max-h-[100%]"} bg-white-100 shadow-[0px_0px_15px_11px_#71717126] ${noSpacePopup ? "overflow-hidden" : "p-4"} rounded-3xl ${mobilePopupStyle ? "rounded-b-none" : ""} sm:rounded-b-3xl w-full absolute bottom-0 left-0 right-0 ${maxWidth} sm:static border-[0.5px] border-gray-250`}
        style={{
          position: !mobilePopupStyle ? "static" : ""
        }}>
        <div
          className="relative flex items-center justify-between mb-4"
          style={{ marginBottom: noSpacePopup && 0 }}>
          {!hideOnActionBtnOnly && !noSpacePopup && (
            <button
              className="w-10 h-10 cursor-pointer"
              aria-label="close"
              title="close"
              onClick={() => setOpen(false)}>
              <CloseIcon />
            </button>
          )}
          {mobilePopupStyle && (
            <span className="block sm:hidden h-[2px] w-20 rounded-lg bg-gray-100 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2" />
          )}
          <span>
            {btnText && callback && (
              <Button onClick={callback} text={btnText} className={"text-base !px-3 !py-2"} />
            )}
          </span>
          {noSpacePopup && (
            <button
              aria-label="close"
              title="close"
              className="w-10 h-10 cursor-pointer absolute left-4 top-4"
              onClick={() => setOpen(false)}>
              <span className="w-6 h-6 absolute bg-orange-100 top-2 left-2 rounded-full" />
              <CloseIcon fill="#fff" />
            </button>
          )}
        </div>

        {open && (
          <div
            className={`h-full max-h-[80%] overflow-y-auto sm:max-h-auto sm:h-fit sm:max-h-auto ${!noSpacePopup ? "px-2" : "px-0"} ${bottomPadding && !noSpacePopup ? "pb-40 sm:pb-10" : ""}`}>
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export default Popup;
