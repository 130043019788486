import React, { useContext } from "react";
import { ProgressBar } from "react-loader-spinner";

import { WorkshopContext } from "../../contexts/workshop.context";

const Button = ({
  text,
  onClick,
  disabled,
  loading,
  htmlType = "button",
  type = "primary",
  className,
  children
}) => {
  const { themeConfig: { primary, primary50 } = {} } = useContext(WorkshopContext);

  return (
    <button
      type={htmlType}
      className={`flex flex-row gap-3 items-center justify-center w-full px-6 py-4 bg-gray-200 text-xl font-bold text-black-100 truncate rounded-lg cursor-pointer hover:opacity-70 disabled:!opacity-50 disabled:!cursor-not-allowed ${className}`}
      style={{
        backgroundColor: type === "primary" ? primary : type === "outline" ? "transparent" : "",
        color: type === "primary" ? "#ffffff" : type === "outline" ? primary : "",
        border: type === "outline" ? `2px solid ${primary50}` : "",
        borderRadius: type === "outline" ? "10px" : ""
      }}
      onClick={onClick}
      disabled={disabled}>
      {loading && (
        <span className="w-6 h-6">
          <ProgressBar
            visible={true}
            barColor="#ffffff"
            ariaLabel="button-loading"
            wrapperStyle={{}}
            wrapperClass="bars-wrapper"
          />
        </span>
      )}
      {children} <span dangerouslySetInnerHTML={{ __html: text }} />
    </button>
  );
};

export default Button;
