import { createContext, useState } from "react";

export const WorkshopContext = createContext();

export const WorkshopProvider = (props) => {
  const [socket, setSocket] = useState(null); // Socket connection
  const [platform, setPlatform] = useState("mft");
  const [workshopDetails, setWorkshopDetails] = useState(null);
  const [user, setUser] = useState(null);
  const [themeConfig, setThemeConfig] = useState({
    primary: "#FF0000",
    primary50: "#FF000050",
    primary100: "#FF0000FF"
  });
  const [workshopUsers, setWorkshopUsers] = useState([]);
  const [allUsersWithMods, setAllUsersWithMods] = useState([]); // Contains full list with moderators as well

  return (
    <WorkshopContext.Provider
      value={{
        socket,
        setSocket,
        platform,
        setPlatform,
        workshopDetails,
        setWorkshopDetails,
        user,
        setUser,
        themeConfig,
        setThemeConfig,
        workshopUsers,
        setWorkshopUsers,
        allUsersWithMods,
        setAllUsersWithMods
      }}>
      {props.children}
    </WorkshopContext.Provider>
  );
};
